import HookFormLabel from '@/components/modules/HookFormLabel';
import { useGetTemplatesByIdQuery } from '@/graphql/template/getTemplatesById.generated';
import { TemplateType } from '@/models/template.model';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import cx from 'classnames';
import pluralize from 'pluralize';
import useShouldAnimate from '../../../hooks/useShouldAnimate';
import HeaderImage from './HeaderImage/HeaderImage';
import NameInput from './NameInput';

export interface HeaderMiddleProps {
  isCompressedHeader?: boolean;
  templateName?: string;
}

export default function HeaderMiddle ({
  isCompressedHeader,
  templateName,
}: Readonly<HeaderMiddleProps>): JSX.Element {
  const { newObjectConfig: { templateId }, objectCardData } = useObjectCardContext();

  const objectTemplate = objectCardData?.template;

  // TODO: Update this to use a graphql query for getting template by id
  const { data: templateData } = useGetTemplatesByIdQuery({
    variables: { templateId },
    skip: !templateId,
  });
  const newObjectTemplate = templateData?.getTemplates?.[0];
  const activeTemplate = objectTemplate ?? newObjectTemplate;

  const parentTemplate = objectCardData?.parent?.template;

  const placeholder = activeTemplate?.type === 'task' ? 'Enter driver name' : 'Enter build name';

  const isSubtask = parentTemplate?.type === TemplateType.Task;

  const name = isSubtask ? `Sub${templateName}` : templateName;
  const shouldAnimate = useShouldAnimate();

  return (
    <div
      className={cx('flex absolute items-end transition-all transform-gpu duration-500 z-10', {
        'animate-object-change': shouldAnimate,
        'px-[24px] pr-0 gap-[24px] w-[calc(100%-350px)] top-[18px]': isCompressedHeader,
        'p-[24px] pt-[16px] gap-[30px] w-full top-[78px]': !isCompressedHeader,
      })}
    >
      <HeaderImage isCompressedHeader={isCompressedHeader} />
      <div
        className={cx('flex flex-col', {
          'w-full': isCompressedHeader,
          'grow': !isCompressedHeader,
        })}
      >
        <div
          className={cx('px-3 truncate effra-xs text-neutral-500 shrink-0 transition-all transform-gpu duration-500', {
            'opacity-0 h-0': isCompressedHeader,
            'opacity-100 h-[12px]': !isCompressedHeader,
          })}
        >
          <HookFormLabel name='name' label={name && pluralize.singular(name)} className='text-neutral-500' />
        </div>
        <NameInput isCompressedHeader={isCompressedHeader} placeholder={placeholder} />
      </div>
    </div>
  );
}
