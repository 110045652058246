import { useAppSelector } from '@/state/hooks';
import { getLminusCalcDueDate, getLminusCalcStartDate } from '@/util/time.functions';
import { useWatch } from 'react-hook-form';
import useToggleGroup from '../useToggleGroup';
import { getRelation } from './calculators.utils';
import { selectLaunchDateFromParent } from './selectors/parentWithLaunchDate.selector';

const DEFAULT_RELATIONS = {
  self: {
    fieldDependencies: {
      lMinusMonthsStart: {
        type: 'number',
        fields: ['relativeStartMonths'],
      },
      lMinusDaysStart: {
        type: 'number',
        fields: ['relativeStartDays'],
      },
      lMinusMonthsDue: {
        type: 'number',
        fields: ['relativeEndMonths'],
      },
      lMinusDaysDue: {
        type: 'number',
        fields: ['relativeEndDays'],
      },
    },
  },
};

interface LminusDateCalcProps {
  fieldToWatch: string;
  toggleValue: string;
  enableSubscription: boolean;
}
export default function LminusDateCalc ({ fieldToWatch, toggleValue, enableSubscription }: LminusDateCalcProps) {
  const shouldShow = useToggleGroup({
    fieldToWatch,
    toggleValue,
    enableSubscription,
  });

  const currentId = useWatch({ name: 'id' });
  const data = useWatch({ name: 'data' });

  const launchDate = useAppSelector(
    selectLaunchDateFromParent(currentId),
  );

  const {
    lMinusMonthsStart,
    lMinusDaysStart,
    lMinusMonthsDue,
    lMinusDaysDue,
  } = getRelation(DEFAULT_RELATIONS.self, data);

  const startOffsets = [lMinusMonthsStart, lMinusDaysStart, launchDate];
  const endOffsets = [lMinusMonthsDue, lMinusDaysDue, launchDate];

  const hasStartValue = +lMinusMonthsStart || +lMinusDaysStart;
  const hasEndValue = +lMinusMonthsDue || +lMinusDaysDue;

  const startDate = getLminusCalcStartDate(...startOffsets);
  const endDate = getLminusCalcDueDate(...endOffsets);

  const actualStartDate = hasEndValue && !hasStartValue ? endDate : startDate;

  return shouldShow ?
    (
      <div className='flex gap-4 items-center mt-8'>
        <div className='flex gap-4'>
          <div className='flex flex-col gap-1'>
            <div className='effra-xs text-black/40'>Relative Start Date</div>
            <div className='effra-12'>{actualStartDate.format('MMM DD, YYYY')}</div>
          </div>
          <div className='flex flex-col gap-1'>
            <div className='effra-xs text-black/40'>Relative Target Date</div>
            <div className='effra-12'>{endDate.format('MMM DD, YYYY')}</div>
          </div>
        </div>
        <p className='text-black/40'>
          These dates are automatically calculated in relation <br />{' '}
          to the launch date of this Driver&apos;s parent Mission
        </p>
      </div>
    ) :
    null;
}
