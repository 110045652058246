import type { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeftToLine, faArrowRightToLine, faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { useCallback, useMemo } from 'react';

export interface OffSetPaginationProps {
  selectedPage: number;
  pageCount: number;
  onPageChange: (page: number) => void;
}

const ICON_SIZE: SizeProp = 'xs';

export const OffSetPagination = ({ selectedPage, pageCount, onPageChange }: OffSetPaginationProps) => {
  const pageNumbers = useMemo(() => Array.from({ length: pageCount }, (_, i) => i + 1), [pageCount]);
  const shouldTruncatePageOptions = pageNumbers.length > 4;
  const truncatedPageOptions = useMemo(() => shouldTruncatePageOptions ? pageNumbers.slice(0, 3) : [], [
    shouldTruncatePageOptions,
    pageNumbers,
  ]);
  const shouldDisablePrevious = selectedPage === 1;
  const shouldDisableNext = selectedPage === pageCount;

  const handleGoToFirstPage = useCallback(() => {
    if (shouldDisablePrevious) return;
    onPageChange(1);
  }, [onPageChange, shouldDisablePrevious]);

  const handleGoToPreviousPage = useCallback(() => {
    if (shouldDisablePrevious) return;
    onPageChange(selectedPage - 1);
  }, [onPageChange, selectedPage, shouldDisablePrevious]);

  const handleGoToNextPage = useCallback(() => {
    if (shouldDisableNext) return;
    onPageChange(selectedPage + 1);
  }, [onPageChange, selectedPage, shouldDisableNext]);

  const handleGoToLastPage = useCallback(() => {
    if (shouldDisableNext) return;
    onPageChange(pageCount);
  }, [onPageChange, pageCount, shouldDisableNext]);

  return (
    <div className='flex gap-2 items-center'>
      <FontAwesomeIcon
        onClick={handleGoToFirstPage}
        className={cx({
          'text-gray-60 cursor-not-allowed': shouldDisablePrevious,
          'cursor-pointer text-primary': !shouldDisablePrevious,
        })}
        size={ICON_SIZE}
        icon={faArrowLeftToLine}
      />

      <FontAwesomeIcon
        onClick={handleGoToPreviousPage}
        className={cx({
          'text-gray-60 cursor-not-allowed': shouldDisablePrevious,
          'cursor-pointer text-primary': !shouldDisablePrevious,
        })}
        size={ICON_SIZE}
        icon={faChevronLeft}
      />
      {shouldTruncatePageOptions ?
        (
          <>
            {truncatedPageOptions.map(page => {
              const isSelected = selectedPage === page;
              return <Page key={page} pageNum={page} onPageChange={onPageChange} isSelected={isSelected} />;
            })} <span className='text-primary'>...</span>
            <Page
              pageNum={pageNumbers[pageNumbers.length - 1]}
              onPageChange={onPageChange}
              isSelected={selectedPage === pageNumbers[pageNumbers.length - 1]}
            />
          </>
        ) :
        pageNumbers.map((page) => {
          const isSelected = selectedPage === page;
          return (
            <Page
              key={page}
              pageNum={page}
              onPageChange={onPageChange}
              isSelected={isSelected}
            />
          );
        })}
      <FontAwesomeIcon
        onClick={handleGoToNextPage}
        className={cx({
          'text-gray-60 cursor-not-allowed': shouldDisableNext,
          'cursor-pointer text-primary': !shouldDisableNext,
        })}
        size={ICON_SIZE}
        icon={faChevronRight}
      />
      <FontAwesomeIcon
        onClick={handleGoToLastPage}
        className={cx({
          'text-gray-60 cursor-not-allowed': shouldDisableNext,
          'cursor-pointer text-primary': !shouldDisableNext,
        })}
        size={ICON_SIZE}
        icon={faArrowRightToLine}
      />
    </div>
  );
};

interface PageProps {
  pageNum: number;
  onPageChange: (page: number) => void;
  isSelected?: boolean;
}

const Page = ({ pageNum, onPageChange, isSelected }: PageProps) => {
  return (
    <div
      className={cx('text-gray-60 effra-12 font-normal transition-all', {
        'text-primary underline': isSelected,
        'cursor-pointer hover:text-gray-40': !isSelected,
      })}
      onClick={() => onPageChange(pageNum)}
    >
      {pageNum}
    </div>
  );
};
