import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { faCalendarStar } from '@fortawesome/sharp-regular-svg-icons';

import SidenavTab from './SidenavTab';

// Sidenav Tab Sections
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { type FilterQueryParams, useQueryParams } from '@/hooks/useQueryParams';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FiltersTabs from '../TabSections/FiltersTabs/FiltersTabs';
import ObjectTabs from '../TabSections/ObjectTabs';
import OpsTabs from '../TabSections/OpsTabs';
import SettingsTabs from '../TabSections/SettingsTabs';
import TeamsTabs from '../TabSections/Teams/TeamTabs';

interface SidenavBodyProps {
  isOnSettingsPage: boolean;
}

export default function SidenavBody ({ isOnSettingsPage }: SidenavBodyProps): JSX.Element {
  const { teams: canAccessTeamsFeature } = useFlags();
  const router = useRouter();
  const loggedInUser = useLoggedInUser();
  const { queryParams } = useQueryParams<FilterQueryParams>();

  const [shouldShowOverflowBlur, setShouldShowOverflowBlur] = useState({ top: false, bottom: true });

  // Handles showing and hiding overflow blur elements based on scroll position
  const onBodyScroll = (event) => {
    if (event.target.scrollTop === 0) {
      setShouldShowOverflowBlur({ top: false, bottom: true });
    } else if (
      Math.floor((event.target.scrollTop as number) + (event.target.clientHeight as number)) + 10 >=
        event.target.scrollHeight
    ) {
      setShouldShowOverflowBlur({ top: true, bottom: false });
    } else {
      setShouldShowOverflowBlur({ top: true, bottom: true });
    }
  };
  return (
    <>
      {shouldShowOverflowBlur.top && (
        <div className='absolute z-[2] top-0 left-0 w-full h-[24px] pointer-events-none bg-gradient-to-t from-transparent to-[#5a0ca2]' />
      )}
      <div
        onScroll={onBodyScroll}
        className='overflow-auto shrink grow no-scrollbar flex flex-col gap-[16px] items-center @expanded/sidenav:items-stretch relative'
      >
        {isOnSettingsPage ? <SettingsTabs /> : (
          <>
            <SidenavTab
              name='Timeline'
              icon={<FontAwesomeIcon icon={faCalendarStar} />}
              href='/schedule'
              isActive={router.pathname === '/schedule' && !queryParams?.filter}
            />
            {Boolean(loggedInUser?.filters?.length) && <FiltersTabs />}
            {canAccessTeamsFeature && <TeamsTabs />}
            <ObjectTabs />
            <OpsTabs />
          </>
        )}
      </div>
      {shouldShowOverflowBlur.bottom && (
        <div className='absolute z-[2] bottom-0 left-0 w-full h-[24px] pointer-events-none bg-gradient-to-b from-transparent to-[#5a0ca2]' />
      )}
    </>
  );
}
