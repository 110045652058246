import InfoTooltip from '@/components/tooltip/InfoTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf } from '@fortawesome/sharp-solid-svg-icons';

export default function TotalResourceCost ({ cost }: { cost: number }): JSX.Element {
  return (
    <InfoTooltip
      tooltipClassName='w-[25ch]'
      message={`Calculated total workload of\n all drivers under this build.`}
    >
      <div className='flex items-center px-3 gap-[8px]'>
        <FontAwesomeIcon icon={faHourglassHalf} />
        <div className='cursor-default effra-12'>{cost}</div>
      </div>
    </InfoTooltip>
  );
}
