import type { User } from '@/__generated__/types';
import { datadogLogs, type LogsEvent } from '@datadog/browser-logs';
import { datadogRum, type RumEvent } from '@datadog/browser-rum';
import { useEffect, useMemo } from 'react';
import { env } from './env.utils';

function santizePasswordResetData (event: RumEvent | LogsEvent): void {
  // remove sensitive data from the URL
  if (event?.view?.url?.includes('reset-password')) {
    const { hostname } = new URL(event.view.url);
    event.view.url = `https://${hostname}/reset-password`;
  }
}

export default function useInitDataDog (
  { session: { user = undefined } }: { session: { user?: User } },
): void {
  const { id, firstName, lastName, email, isTesting } = user ?? {};
  const name = `${firstName} ${lastName}`;
  const currentUser = useMemo(
    () =>
      id
        ? { id, name, email }
        : { id: null, name: 'Anonymous', email: 'Anonymous' },
    [id, name, email],
  );

  const isDoDEnvironment = ['il2', 'il4', 'il5', 'il6'].includes(
    env('NEXT_PUBLIC_STAGE'),
  );

  useEffect(() => {
    if (id && name && email && !isDoDEnvironment && !isTesting) {
      datadogRum.setUser(currentUser);
    }
  }, [id, name, email, isDoDEnvironment, isTesting, currentUser]);

  useEffect(() => {
    if (!isDoDEnvironment && !isTesting) {
      datadogLogs.init({
        beforeSend: (event) => {
          santizePasswordResetData(event);
        },
        clientToken: env('NEXT_PUBLIC_DATADOG_CLIENT_TOKEN'),
        site: process.env.NEXT_PUBLIC_DATADOG_SITE,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        forwardConsoleLogs: ['info', 'warn', 'error'],
      });

      datadogRum.init({
        beforeSend: (event) => {
          santizePasswordResetData(event);
        },
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
        clientToken: env('NEXT_PUBLIC_DATADOG_CLIENT_TOKEN'),
        site: process.env.NEXT_PUBLIC_DATADOG_SITE,
        service: 'integrate-app',
        env: env('NEXT_PUBLIC_STAGE'),
        version: process.env.NEXT_PUBLIC_VERSION ?? 'local',
        sessionSampleRate: 100,
        trackUserInteractions: true,
        trackFrustrations: true,
        trackResources: true,
      });

      datadogRum.startSessionReplayRecording();
    }
  }, []);
}
