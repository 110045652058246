import { useObjectCardScrollContext } from '@/components/common/ModularObject/Card/ObjectCardScroll.context';
import { useGetAllTasksQuery } from '@/components/Schedule/queries/getAllTasks.generated';
import { Button } from '@/designSystemComponents/Button';
import { useModal } from '@/hooks/useModal';
import { useNestingMutations } from '@/hooks/useNestingMutations';
import { type Integration } from '@/models/integration.model';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { createRef, type Ref, useCallback, useEffect, useMemo, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import CreateNewTaskModal from '../CreateNewTaskModal/CreateNewTaskModal';
import MegaModal from '../MegaModal/MegaModal';
import MegaModalEmptyList from '../MegaModal/MegaModalEmptyList/MegaModalEmptyList';
import MegaModalHeader from '../MegaModal/MegaModalHeader/MegaModalHeader';
import MegaModalSearchInput from '../MegaModal/MegaModalSearchInput/MegaModalSearchInput';
import SelectTasksContainer from './SelectTasksContainer';

interface SelectTasksToAddModalProps {
  hardwareId?: string;
  preselectedId?: string;
  onSubmit: (integrations: Integration[]) => void;
}

const DEFAULT_PAGE_SIZE = 20;
export default function SelectTasksToAddModal ({
  hardwareId,
  preselectedId,
  onSubmit,
}: Readonly<SelectTasksToAddModalProps>): JSX.Element {
  const inputRef: Ref<HTMLInputElement> = createRef();
  const [search, setSearch] = useState<string>('');
  const [selectedTasks, setSelectedTasks] = useState(preselectedId ? [preselectedId] : []);
  const { closeModal, showModal } = useModal();
  const { scrollToId } = useObjectCardScrollContext();
  const { openViewObjectCardDrawer } = useObjectCardContext();
  const { addChild } = useNestingMutations();

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const { data, loading, previousData } = useGetAllTasksQuery({ fetchPolicy: 'cache-and-network' });
  const getAllTasks = loading ? previousData?.getAllTasks : data?.getAllTasks;

  const [taskCountToShow, setTaskCountToShow] = useState(DEFAULT_PAGE_SIZE);
  const loadMoreTasks = useCallback(() => {
    const isNextPageSizeLargerThanTaskList = taskCountToShow + DEFAULT_PAGE_SIZE > (getAllTasks?.length || 0);
    if (isNextPageSizeLargerThanTaskList) {
      return setTaskCountToShow((getAllTasks?.length || 0) - 1);
    }
    return setTaskCountToShow(taskCountToShow + DEFAULT_PAGE_SIZE);
  }, [taskCountToShow, getAllTasks?.length]);
  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: true,
    onLoadMore: loadMoreTasks,
    disabled: false,
  });

  const filteredTasks = useMemo(() => {
    if (!getAllTasks) return [];

    return getAllTasks?.filter((task) => {
      const taskName = task?.name?.toLowerCase();
      const parentName = task?.parent?.name?.toLowerCase();
      const searchLowerCase = search.toLowerCase();

      return (
        (taskName?.includes(searchLowerCase)) ||
        (parentName?.includes(searchLowerCase)) ||
        !searchLowerCase
      );
    }).sort((a, b) => (a.id === preselectedId ? -1 : b.id === preselectedId ? 1 : 0)).slice(0, taskCountToShow);
  }, [
    getAllTasks,
    preselectedId,
    search,
    taskCountToShow,
  ]);

  const handleAddToObject = async () => {
    const taskIntegrations = selectedTasks.map((taskId) => {
      const taskIntegration: Integration = {
        objectId: taskId,
        objectType: 'modular_object',
        parentId: hardwareId,
        parentType: 'modular_object',
        isPending: true,
        asTask: true,
      };
      return taskIntegration;
    });

    scrollToId('drivers-section');
    onSubmit(taskIntegrations);
    closeModal();
  };

  const handleSelect = (e) => {
    const taskId = e.target.value;

    setSelectedTasks((prevSelectedTasks) => {
      if (preselectedId && taskId === preselectedId) {
        return prevSelectedTasks.includes(taskId)
          ? prevSelectedTasks.filter((id) => id !== taskId)
          : [...prevSelectedTasks, taskId];
      }
      return e.target.checked
        ? [...prevSelectedTasks, taskId]
        : prevSelectedTasks.filter((id) => id !== taskId);
    });
  };

  const handleCreateNewTask = useCallback(() => {
    showModal(
      <CreateNewTaskModal
        hardwareId={hardwareId}
        afterObjectCreationSave={(createdTask) => {
          showModal(
            <SelectTasksToAddModal
              hardwareId={hardwareId}
              preselectedId={createdTask.id}
              onSubmit={async (integrations) => {
                await addChild({
                  variables: {
                    input: integrations.map(({ parentId, objectId }) => ({
                      parentId,
                      objectId,
                    })),
                  },
                });
                closeModal();
              }}
            />,
            { isMegaModal: true, showCloseIcon: false },
          );
          openViewObjectCardDrawer({ modularObjectId: hardwareId });
        }}
      />,
      { isMegaModal: true, showCloseIcon: false },
    );
  }, [addChild, closeModal, hardwareId, openViewObjectCardDrawer, showModal]);

  return (
    <MegaModal className='w-screen gap-[24px] h-[80vh]'>
      <MegaModalHeader title='Select Drivers to Add' />
      <div className='flex flex-col min-h-0 grow'>
        <MegaModalSearchInput
          ref={inputRef}
          value={search}
          onChange={e => {
            setSearch(e.target.value);
          }}
        />
        {loading && (
          <div className='flex flex-col gap-2 mt-[24px]'>
            <div className='text-xs tracking-widest text-gray-400 uppercase'>
              Results
            </div>
            <MegaModalEmptyList
              isLoading={loading}
              message='Please wait while we load your drivers.'
              showButton={false}
            />
          </div>
        )}
        {!loading && !filteredTasks.length && (
          <div className='flex flex-col gap-2 mt-[24px]'>
            <div className='text-xs tracking-widest text-gray-400 uppercase'>
              Results
            </div>
            <MegaModalEmptyList
              message='No drivers match your search.'
              showButton={false}
            />
          </div>
        )}
        {!loading && filteredTasks.length && (
          <SelectTasksContainer
            handleSelect={handleSelect}
            tasks={filteredTasks}
            selectedTasks={selectedTasks}
            setSelectedTasks={setSelectedTasks}
          />
        )}
        {/* Simply for infinite loading */}
        <div ref={sentryRef} />
      </div>
      <div className='flex gap-8'>
        <Button
          figmaProps={{
            style: 'outline',
            size: 'small',
          }}
          onClick={handleCreateNewTask}
        >
          <span className='uppercase whitespace-nowrap truncate'>new driver</span>
        </Button>
        <Button
          figmaProps={{
            style: 'fill',
            size: 'small',
          }}
          onClick={handleAddToObject}
          disabled={!selectedTasks.length}
        >
          <span className='uppercase whitespace-nowrap truncate'>add to build</span>
        </Button>
      </div>
    </MegaModal>
  );
}
