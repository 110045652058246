import Image from 'next/image';
import React from 'react';

interface Props {
  image: string;
  alt: string;
  message: string;
  subMessage?: string;
}

const AstroLizzyMessage: React.FC<Props> = ({ image, alt, message, subMessage }) => {
  return (
    <div className='flex flex-col justify-center items-center bg-transparent grow p-[16px] gap-[16px]'>
      <Image src={image} alt={alt} height={150} width={150} />
      <div className='effra-24'>{message}</div>
      {Boolean(subMessage) && <div className='effra-14'>{subMessage}</div>}
    </div>
  );
};

export default AstroLizzyMessage;
