import { Listbox } from '@headlessui/react';
import cx from 'classnames';
import { Fragment } from 'react';

export type ListBoxButtonProps<I> = {
  placeholder: string;
  selected: { displayText: string; value: string; icon: string };
  initialValue: { displayText: string; value: string; icon?: string };
  button?: typeof DefaultButton;
} & I;

interface DefaultButtonProps
  extends Partial<Pick<ListBoxButtonProps<{ icons: Map<string, JSX.Element> }>, 'selected' | 'initialValue' | 'icons'>>
{
  displayText: string;
  textColor: string;
}

export function DefaultButton ({ textColor, icons, selected, initialValue, displayText }: DefaultButtonProps) {
  return (
    <Fragment>
      <span className={cx('flex gap-2 items-center h-[32px]', textColor)}>
        <span className='flex justify-center items-center w-[16px] h-[16px]'>
          {icons.get(selected?.value ?? initialValue?.value)}
        </span>
        <span>{displayText}</span>
      </span>
      <i className='fa-regular text-gray-60 fa-chevron-down' />
    </Fragment>
  );
}

export function DefaultButtonNoIcon (
  { textColor, displayText }: Pick<DefaultButtonProps, 'displayText' | 'textColor'>,
) {
  return (
    <Fragment>
      <span className={cx('flex items-center h-[32px]', textColor)}>
        <span>{displayText}</span>
      </span>
      <i className='fa-regular text-gray-60 fa-chevron-down' />
    </Fragment>
  );
}

export function DefaultSlimButton ({ textColor, displayText }: Pick<DefaultButtonProps, 'displayText' | 'textColor'>) {
  return (
    <>
      <span className={cx('flex items-center h-[22px]', textColor)}>
        <span>{displayText}</span>
      </span>
      <i className='fa-regular text-gray-60 fa-chevron-down' />
    </>
  );
}

type Icons = { icons: Map<string, JSX.Element> } | { icons?: Map<string, JSX.Element> };

export default function ListBoxButton<T extends ListBoxButtonProps<Icons>> (
  { selected, placeholder, initialValue, icons, button = DefaultButton }: T,
): ReturnType<typeof Listbox.Button> {
  const displayText = selected?.displayText ?? initialValue?.displayText ?? placeholder;
  const textColor = displayText === placeholder ? 'text-gray-60' : 'text-black';

  return (
    <Listbox.Button className='flex justify-between items-center px-2 w-full text-left border border-gray-80 effra-12 rounded-[4px] gap-[4px]'>
      {button({ selected, initialValue, displayText, textColor, icons })}
    </Listbox.Button>
  );
}
