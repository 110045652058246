import type { DependencyBlockType } from '@/__generated__/types';
import { useEditContext } from '@/components';
import { FixedGapBehaviorIcon, NoGapBehavior, NoOverlapGapBehaviorIcon } from '@/components/Icons/GapBehaviorIcons';
import {
  GetModularObjectDependenciesByIdDocument,
  type GetModularObjectDependenciesByIdQuery,
} from '@/components/modals/DependencyModal/getModularObjectDependenciesById.generated';
import StatusTooltip from '@/components/tooltip/StatusTooltip';
import { useModal } from '@/hooks/useModal';
import { THEME_COLORS } from '@/util/constants';
import getIcon from '@/util/getIcon';
import { deleteDependency } from '@/util/requests.functions';
import { useApolloClient } from '@apollo/client';
import { faArrowsLeftRightToLine, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { useCallback } from 'react';
import { ConfirmDeleteDependencyModal } from './ConfirmDeleteDependency';

export interface DependencyCardProps {
  dependency:
    | GetModularObjectDependenciesByIdQuery['getModularObjectByID']['blockedBy'][0]
    | GetModularObjectDependenciesByIdQuery['getModularObjectByID']['blocking'][0];
  dependencyName: string;
  onClick: () => void;
  isCollaboratorObject?: boolean;
  iconName: string;
}

const ICON_MAP: { [key in DependencyBlockType]: typeof NoGapBehavior } = {
  none: NoGapBehavior,
  noOverlap: NoOverlapGapBehaviorIcon,
  strict: FixedGapBehaviorIcon,
};

const GAP_BEHAVIOR_DISPLAY_TEXT: { [key in DependencyBlockType]: string } = {
  none: 'None',
  noOverlap: 'No Overlap',
  strict: 'Fixed',
};

export const DependencyCard = ({
  dependency,
  dependencyName,
  isCollaboratorObject,
  onClick,
  iconName,
}: DependencyCardProps) => {
  const { canUserEdit } = useEditContext();
  const { hasPendingApproval } = dependency;

  const gapBehaviorDisplayText = GAP_BEHAVIOR_DISPLAY_TEXT[dependency?.blockType || 'none'];
  const Icon = ICON_MAP[dependency?.blockType || 'none'];
  const { showModal, closeModal } = useModal();
  const apolloClient = useApolloClient();

  const handleDelete = async () => {
    await deleteDependency([dependency.id]);
    closeModal();
    void apolloClient.refetchQueries({
      include: [GetModularObjectDependenciesByIdDocument],
    });

    // Refetches the queries for the gantt if observable
    apolloClient.reFetchObservableQueries().catch(console.error);
  };

  const confirmDelete = useCallback(() => {
    showModal(<ConfirmDeleteDependencyModal closeModal={closeModal} handleDelete={handleDelete} />, {
      className: '!max-w-[360px]',
    });
  }, [dependency.id]);

  return (
    <div
      data-testid={`dependency-card-${dependencyName}`}
      className={cx('flex w-full group', {
        'cursor-pointer': canUserEdit && !hasPendingApproval,
      })}
    >
      <div className='flex justify-center items-center w-8'>
        {hasPendingApproval && <StatusTooltip status={'waiting for approval'} />}
      </div>
      <div
        className={cx('flex flex-1 flex-col justify-center p-3 pl-4', {
          'bg-white shadow-dependency-card rounded-md': canUserEdit,
          'opacity-40': hasPendingApproval,
        })}
        onClick={canUserEdit ? onClick : null}
      >
        <div className='flex'>
          <div className='flex mr-3 pt-[4px] h-[16px] w-[15px]'>
            {getIcon(iconName)}
          </div>
          <div className='flex flex-col flex-1'>
            <p
              className={cx('effra-14 font-medium', {
                'text-primary': canUserEdit && !isCollaboratorObject,
                'text-tertiary': isCollaboratorObject,
              })}
            >
              {dependencyName}
            </p>

            <div className='flex items-center mt-1 effra-12 text-gray-40'>
              <div className='flex gap-2 items-center mr-5'>
                <Icon fill={THEME_COLORS.gray[40]} height='12px' width='16px' />
                <p>
                  {gapBehaviorDisplayText}
                </p>
              </div>
              <div className='flex gap-2 items-center'>
                <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
                <span>{Math.abs(dependency?.gapYears)}y</span>
                <span>{Math.abs(dependency?.gapMonths)}m</span>
                <span>{Math.abs(dependency?.gapDays)} days</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {canUserEdit && (
        <button
          onClick={confirmDelete}
          type='button'
          // name='delete dependency'
          data-testid={`delete-dependency-${dependencyName}`}
          className='flex invisible justify-center items-center w-8 group-hover:visible text-gray-70'
        >
          <FontAwesomeIcon
            icon={faXmark}
            size='lg'
          />
        </button>
      )}
    </div>
  );
};
