import type { HardwareIntegration, ModularObject } from '@/__generated__/types';
import { useEditContext } from '@/components';
import ControlledDatePicker from '@/components/form/DatePickerInput/ControlledDatePicker';
import { ParentRangeColor } from '@/components/form/DatePickerInput/DatePickerInput';
import ModuleTextInput from '@/components/modules/ModuleTextInput';
import SelectInput from '@/components/modules/SelectBoxInput';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { useGetUserByIdQuery } from '@/components/User/getUserById.generated';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { selectRootState } from '@/state/selectors';
import { addIntegrations } from '@/state/slices/integration.slice';
import { addModularObjects } from '@/state/slices/modularObjects.slice';
import { postIntegration, postModularObject } from '@/util/requests.functions';
import { useApolloClient } from '@apollo/client';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/sharp-solid-svg-icons';
import { isValid } from 'date-fns';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { getTheBandBackTogether } from '../../ModularObject/Card/ObjectCard.util';
import { shapeNewRequirement, shapeNewTask } from './utils';

export default function RapidTaskCreation (): JSX.Element {
  const { reFetchObservableQueries } = useApolloClient();
  const currentUser = useLoggedInUser();
  const { objectCardData } = useObjectCardContext();
  const isTask = objectCardData?.template?.type === 'task';
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectRootState);
  const { templates: templateState } = state;
  const { canUserEdit } = useEditContext();

  const methods = useForm({
    defaultValues: { driverType: '', driverName: '', staticDueDate: null, staticStartDate: null, shallStatement: null },
  });
  const objectId = useWatch({ name: 'id' });
  const startDate = methods.watch('staticStartDate');
  const endDate = methods.watch('staticDueDate');
  const taskName = methods.watch('driverName');
  const shallStatement = methods.watch('shallStatement');
  const taskType = methods.watch('driverType');

  const ownerId = objectCardData?.template?.type === 'task' ? objectCardData?.assigneeId : objectCardData?.ownerId;
  const { data } = useGetUserByIdQuery({ variables: { id: ownerId }, skip: !ownerId });
  const isInternal = currentUser?.organizationId === data?.getUserByID?.organizationId;

  const submitTask = useCallback(
    async (event): Promise<void> => {
      event.stopPropagation();

      if (event?.key !== 'Enter' && event.type !== 'click') {
        return;
      }

      event.preventDefault();

      if (!taskType) {
        addToastError('Please enter a driver type');
        return;
      }

      if (!taskName) {
        addToastError('Please enter a driver name');
        return;
      }

      let newModularObjTask: ModularObject = null;

      if (taskType !== 'Requirement') {
        if (!endDate || !isValid(new Date(endDate))) {
          addToastError('Please enter a due date');
          return;
        }

        newModularObjTask = shapeNewTask({
          subType: taskType,
          name: taskName,
          startDate: startDate ? dayjs(startDate).toISOString() : undefined,
          targetDate: dayjs(endDate).toISOString(),
        });
      } else {
        newModularObjTask = shapeNewRequirement({
          subType: taskType,
          name: taskName,
          shall: shallStatement,
        });

        newModularObjTask.startDate = objectCardData?.startDate;
        newModularObjTask.targetDate = objectCardData?.startDate;
      }

      const newModularObjTasks = await postModularObject([newModularObjTask]);
      const newTask = newModularObjTasks?.[0];

      if (newTask) {
        const milestoneIntegration: HardwareIntegration = {
          objectId: newTask.id,
          objectType: 'modular_object',
          parentId: objectId,
          parentType: 'modular_object',
        } as HardwareIntegration;
        await postIntegration([milestoneIntegration]);

        addToastSuccess('Driver created');

        const taskTemplate = templateState[newTask?.templateId];
        newTask.data = getTheBandBackTogether(taskTemplate?.modules, newTask?.data);
        dispatch(addModularObjects([newTask]));
        // We can dispatch this faux integration since we know no approval is needed
        dispatch(addIntegrations([milestoneIntegration]));

        // Refetch queries to update cached data to include new task
        reFetchObservableQueries().catch(console.error);
      }

      methods.setValue('driverType', taskType);
      methods.setValue('driverName', '');
      methods.setValue('staticStartDate', '');
      methods.setValue('staticDueDate', '');
      methods.setValue('shallStatement', '');
      methods.setFocus('driverName', { shouldSelect: true });
    },
    [taskType, taskName, endDate, startDate, shallStatement],
  );

  if (!canUserEdit) {
    return null;
  }

  const TASK_TYPES = ['Milestone', 'Requirement', 'Task'];

  const options = TASK_TYPES.map((action) => ({
    label: action,
    value: action,
  }));

  let parentRangeColor = ParentRangeColor.Default;

  if (ownerId && !isInternal) {
    parentRangeColor = ParentRangeColor.External;
  } else if (ownerId && isInternal) {
    parentRangeColor = ParentRangeColor.Internal;
  }

  return (
    <FormProvider {...methods}>
      <form
        id='rapid-driver-creation-form'
        className='flex items-center flex-1 gap-3 mx-6 @container/form z-[1]'
        onKeyDown={submitTask}
      >
        <div className='min-w-[92px]'>
          <SelectInput
            name='driverType'
            options={options}
            buttonProps={{ selectedItem: taskType, placeholder: 'Type' }}
            isNotAttribute
          />
        </div>

        <div className='flex items-center gap-2.5 flex-1 shrink @[650px]/form:gap-3'>
          {taskType === 'Requirement' ?
            (
              <>
                <ModuleTextInput
                  className='min-w-[95px]'
                  inputProps={{
                    placeholder: `${isTask ? 'Nested Driver' : 'Driver'} Name`,
                    name: 'driverName',
                    required: true,
                    autoComplete: 'off',
                    isNotAttribute: true,
                  }}
                  disablePrismaticField
                />
                <ModuleTextInput
                  className='grow'
                  inputProps={{
                    placeholder: 'Shall Statement',
                    name: 'shallStatement',
                    required: false,
                    autoComplete: 'off',
                    isNotAttribute: true,
                  }}
                  disablePrismaticField
                />
              </>
            ) :
            (
              <>
                <ModuleTextInput
                  className='min-w-[160px] grow'
                  inputProps={{
                    placeholder: `${isTask ? 'Nested Driver' : 'Driver'} Name`,
                    name: 'driverName',
                    required: true,
                    autoComplete: 'off',
                    isNotAttribute: true,
                  }}
                  disablePrismaticField
                />
                <ControlledDatePicker
                  className='w-[95px] @[650px]/form:w-[130px]'
                  id='staticStartDate'
                  endDate={endDate}
                  parentStartDate={objectCardData?.startDate ? dayjs(objectCardData.startDate).toDate() : null}
                  parentEndDate={objectCardData?.targetDate ? dayjs(objectCardData.targetDate).toDate() : null}
                  parentRangeColor={parentRangeColor}
                  placeholder='Start Date'
                  inputProps={{ isNotAttribute: true }}
                  {...methods.register('staticStartDate')}
                />
                <FontAwesomeIcon className='shrink-0' icon={faArrowRight} />
                <ControlledDatePicker
                  className='w-[95px] @[650px]/form:w-[130px]'
                  id='staticDueDate'
                  startDate={startDate}
                  parentStartDate={objectCardData?.startDate ? dayjs(objectCardData.startDate).toDate() : null}
                  parentEndDate={objectCardData?.targetDate ? dayjs(objectCardData.targetDate).toDate() : null}
                  parentRangeColor={parentRangeColor}
                  placeholder='Target Date'
                  inputProps={{ isNotAttribute: true }}
                  {...methods.register('staticDueDate')}
                />
              </>
            )}
        </div>
        <button
          id='rapid-driver-creation-button'
          className='py-3 px-3.5 btn-primary-hollow effra-xs shrink'
          onClick={submitTask}
        >
          <span className='hidden @[620px]/form:block'>Create</span>
          <span className='block @[620px]/form:hidden'>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        </button>
      </form>
    </FormProvider>
  );
}
