import type { Team } from '@/__generated__/types';
import { ConfirmationModal } from '@/components/modals';
import { useAddTeamMembersMutation } from '@/components/modals/AddTeamMembersModal/AddTeamMembers.generated';
import SelectBoxButton from '@/components/modules/SelectBoxInput/SelectBoxButton';
import SelectBoxOptions from '@/components/modules/SelectBoxInput/SelectBoxOptions';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { Button } from '@/designSystemComponents/Button';
import { useRemoveTeamMemberMutation } from '@/graphql/team/RemoveTeamMember.generated';
import { TeamMembersDocument } from '@/graphql/team/TeamMembers.generated';
import { useModal } from '@/hooks/useModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUsers } from '@fortawesome/sharp-solid-svg-icons';
import { Listbox } from '@headlessui/react';
import { useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { GetUserByIdDocument, type UserByIdFragment } from '../getUserById.generated';

interface UserTeamsModalProps {
  user: UserByIdFragment;
  orgTeams: Team[];
  closeModal: () => void;
}

export default function UserTeamsModal ({ user, orgTeams, closeModal }: UserTeamsModalProps): JSX.Element {
  const { showModal, closeModal: closeInnerModal } = useModal();
  const [popperElement, setPopperElement] = useState(null);
  const [popperRefElement, setPopperRefElement] = useState(null);

  const { styles, attributes } = usePopper(popperRefElement, popperElement);

  const [selectedTeamId, setSelectedTeamId] = useState<string>();
  const selectedItem = useMemo(() => orgTeams.find((team) => team.id === selectedTeamId), [selectedTeamId, orgTeams]);
  const [addTeamMembers] = useAddTeamMembersMutation();
  const [removeTeamMember] = useRemoveTeamMemberMutation();

  const teams = user?.teams;

  const handleTeamChange = (teamId: string) => {
    setSelectedTeamId(teamId);
  };

  const handleAddUserToTeam = () => {
    void addTeamMembers({
      variables: {
        teamId: selectedTeamId,
        userIds: [user.id],
      },
      onCompleted: () => {
        closeModal();
        addToastSuccess('User added to team');
      },
      onError: (error) => {
        console.error(error);
        addToastError(`Failed to add user to team, ${error.message}`);
      },
      refetchQueries: [GetUserByIdDocument, TeamMembersDocument],
    });
  };

  const submitRemoveTeamMember = (teamId: string) => {
    void removeTeamMember({
      variables: {
        id: null,
        teamId,
        userId: user.id,
      },
      onCompleted: () => {
        closeInnerModal();
        addToastSuccess('User removed from team');
      },
      onError: (error) => {
        console.error(error);
        addToastError(`Failed to remove user from team, ${error.message}`);
      },
      refetchQueries: [GetUserByIdDocument, TeamMembersDocument],
    });
  };

  const confirmRemove = (teamId: string) => {
    showModal(
      <ConfirmationModal
        onConfirm={() => submitRemoveTeamMember(teamId)}
        onClose={closeInnerModal}
        header='Are you sure?'
        message='This user will be removed as a member of this team.'
        confirmText='Remove'
      />,
      {
        className: '!w-[500px]',
      },
    );
  };

  const teamOptions = useMemo(() =>
    orgTeams.reduce((acc, team) => {
      if (!teams?.some((t) => t.id === team.id)) {
        acc.push({ value: team.id, label: team.name });
      }
      return acc;
    }, []), [orgTeams, teams]);

  return (
    <div className='flex flex-col w-full gap-[24px] p-[8px]'>
      <div className='effra-20'>{user?.fullName}&apos;s Teams</div>
      {teams?.map((team) => (
        <div key={team?.id} className='flex items-center gap-[8px] effra-16'>
          <FontAwesomeIcon icon={faUsers} />
          {team?.name}
          <button
            onClick={() => {
              confirmRemove(team?.id);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      ))}
      <div className='relative light'>
        <Listbox
          value={selectedTeamId}
          onChange={handleTeamChange}
        >
          <SelectBoxButton selectedItem={selectedItem?.name} ref={setPopperRefElement} placeholder='Choose team' />
          <SelectBoxOptions
            options={teamOptions}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          />
        </Listbox>
      </div>
      <Button
        onClick={handleAddUserToTeam}
        figmaProps={{ size: 'large', style: 'outline' }}
        disabled={!selectedTeamId}
      >
        Add to Team
      </Button>
    </div>
  );
}
