import type { ScheduleView, TimelineZoomLevel } from '@/components/Schedule/constants';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

export interface UserProfileQueryParams extends Record<string, string> {
  activeProfileId?: string;
}

export interface ScheduleViewQueryParams extends Record<string, string> {
  scheduleView?: ScheduleView;
}

export interface TimelineQueryParams extends Record<string, string> {
  zoom?: TimelineZoomLevel;
}

export interface TeamQueryParams extends Record<string, string> {
  teamId?: string;
}

export interface FilterQueryParams extends Record<string, string> {
  filter?: string;
}

export interface SSOQueryParams extends Record<string, string> {
  code?: string;
  mfa?: string;
  token?: string;
  email?: string;
}

export function useQueryParams<T extends Record<string, string>> () {
  const { pathname, query, push } = useRouter();

  const queryParams = query as T;

  const updateUrlParams = useCallback(
    (newParams: T) => {
      void push({ pathname, query: newParams }, null, { shallow: true });
    },
    [pathname, push],
  );

  const clearUrlParams = useCallback(() => {
    void push({ pathname, query: {} }, null, { shallow: true });
  }, [pathname, push]);

  const api = useMemo(() => {
    return {
      queryParams,
      updateUrlParams,
      clearUrlParams,
    };
  }, [queryParams, updateUrlParams, clearUrlParams]);

  return api;
}
