import { useGetUserByIdQuery } from '@/components/User/getUserById.generated';
import UserProvider from '@/components/User/User.context';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import cx from 'classnames';
import type { UserPermissionOnObjectPropsFragment } from './UserPermissionOnObjectProps.generated';
interface PermissionPropsDeprecated {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  isBlackFont?: boolean;
}
/**
 * @deprecated Use `Permission` instead
 */
export default function PermissionDeprecated (
  { className, data, isBlackFont }: Readonly<PermissionPropsDeprecated>,
): JSX.Element {
  const loggedInUser = useLoggedInUser();

  const { data: ownerData } = useGetUserByIdQuery({
    variables: { id: data?.ownerId },
    skip: !data?.ownerId,
  });

  const owner = ownerData?.getUserByID;
  const isOwner = owner?.id === loggedInUser?.id;
  const isInternal = owner?.organizationId === loggedInUser?.organizationId;
  const permission = `${data?.permission?.charAt(0)?.toUpperCase() || ''}${
    data?.permission?.slice(1)?.toLowerCase() || ''
  }`;

  return (
    <UserProvider user={data?.owner} currentUser={loggedInUser}>
      <div
        className={cx(className, {
          'text-primary': !isBlackFont && (isOwner || isInternal),
          'text-tertiary': !isBlackFont && !isOwner && !isInternal,
        })}
      >
        {permission}
      </div>
    </UserProvider>
  );
}

export interface UserPermissionOnObjectProps {
  className?: string;
  modularObject: UserPermissionOnObjectPropsFragment;
  isBlackFont?: boolean;
}

export const UserPermissionOnObject = (
  { className, modularObject, isBlackFont }: UserPermissionOnObjectProps,
) => {
  const loggedInUser = useLoggedInUser();
  const owner = modularObject.owner;
  const isOwner = owner?.id === loggedInUser?.id;
  const isInternal = owner?.organizationId === loggedInUser?.organizationId;

  const permission = isOwner
    ? 'Owner'
    : modularObject.shares.find((share) => share.userId === loggedInUser?.id)?.role ?? 'Viewer';

  return (
    <div
      className={cx(className, {
        'text-primary': !isBlackFont && (isOwner || isInternal),
        'text-tertiary': !isBlackFont && (!isOwner && !isInternal),
      })}
    >
      {permission}
    </div>
  );
};
