import type { QueryFieldPolicy } from '@/__generated__/apollo-type-policies';
import type { ModularObject } from '@/__generated__/types';
import { EditContextProvider } from '@/components/cards';
import PrismaticProvider from '@/components/Contexts/prismatic.context';
import { GetTopLevelModularObjectsDocument } from '@/components/Schedule/queries/getTopLevelModularObjects.generated';
import { addSaveSuccessToast, addToastError } from '@/components/Toast/utils';
import DisabledObjectMessage from '@/components/tooltip/DisabledObjectMessage';
import { GetModularObjectsByTemplateIdDocument } from '@/graphql/modularObject/getModularObjectsByTemplateId.generated';
import {
  GetTemplatesUsedByModularObjectsDocument,
} from '@/graphql/sideNavigation/getTemplatesUsedByModularObjects.generated';
import { useDeveloperMode } from '@/hooks/useDeveloperMode';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { deleteModularObjects } from '@/util/requests.functions';
import { type Reference, useApolloClient } from '@apollo/client';
import { uniq } from 'ramda';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useCopyToClipboard } from 'usehooks-ts';
import CollaborationColumn from '../../Collaboration/CollaborationColumn';
import ListActionProvider from '../../Tasks/TaskSection/ListActions.context';
import TaskListProvider from '../../Tasks/TaskSection/TaskList.context';
import { useDuplicateModularObjectsMutation } from '../../Tasks/TaskSection/TaskListActions/ConfirmationMessage/duplcateModularObject.generated';
import TaskListActions from '../../Tasks/TaskSection/TaskListActions/TaskListActions';
import Header from '../Card v2/Header/Header';
import { type GetObjectCardObjectQuery } from './GetObjectCardObject.generated';
import ObjectProvider from './Object.context';
import { getTheBandBackTogether } from './ObjectCard.util';
import ObjectCardContent from './ObjectCardContent';
import ObjectCardScrollingContainer from './ObjectCardScrollingContainer';

export interface ObjectCardProps {
  forExternalLinks?: boolean;
  object: GetObjectCardObjectQuery['getModularObjectByIDs'][0];
}

function ObjectCard ({ forExternalLinks = false }: Readonly<ObjectCardProps>): JSX.Element {
  const apolloClient = useApolloClient();

  const loggedInUser = useLoggedInUser();
  const [duplicateObjects] = useDuplicateModularObjectsMutation();

  const {
    closeObjectCardDrawer,
    activeModularObjectId,
    isCollabModalOpen,
    objectCardData,
  } = useObjectCardContext();

  const collabModalStateRef = useRef(false);
  const [isCollaborationOpen, setIsCollaborationOpen] = useState(isCollabModalOpen);
  const toggleCollabModal = useCallback((): void => {
    setIsCollaborationOpen(o => {
      collabModalStateRef.current = !o;
      return !o;
    });
  }, [collabModalStateRef]);

  const activeObject = objectCardData;

  const [, copy] = useCopyToClipboard();
  const { isDeveloperModeEnabled } = useDeveloperMode();
  const handleCopy = (objectId: string) => () => {
    copy(objectId)
      .then(() => {
        // eslint-disable-next-line no-console
        console.log('Copied!', { activeModularObjectId: objectId });
      })
      .catch(error => {
        console.error('Failed to copy!', error);
      });
  };

  if (isDeveloperModeEnabled) {
    handleCopy(activeModularObjectId)();
  }

  const activeTemplate = activeObject?.template;

  const [userPermission, canUserEdit] = useMemo(() => {
    if (!loggedInUser) {
      return ['Viewer', false];
    }

    if (!activeObject?.id || activeObject?.ownerId === loggedInUser?.id) {
      return ['Owner', true];
    }

    const share = Object.values(activeObject.shares || {})?.find((share) => share?.userId === loggedInUser?.id);

    return [share?.role, share?.role === 'Editor'];
  }, [loggedInUser, activeObject?.id, activeObject?.ownerId, activeObject?.shares]);

  const getDefaultValues = useCallback(() => {
    const data = forExternalLinks
      ? activeObject?.data
      : getTheBandBackTogether(activeTemplate?.modules, activeObject?.data, activeObject?.customFields ?? {});

    return {
      ...activeObject,
      data,
      visibility: activeObject?.visibility || 'Private',
    };
  }, [activeObject, activeTemplate?.modules, forExternalLinks]);

  const methods = useForm({
    defaultValues: getDefaultValues(),
    values: getDefaultValues(),
  });

  const _onClose = useCallback(async (): Promise<void> => {
    // Reset the form
    methods.reset();

    closeObjectCardDrawer();
    await Promise.resolve();
  }, [closeObjectCardDrawer, methods]);

  const handleCloseCard = useCallback((collaborationIsOpen): void => {
    if (collaborationIsOpen.current) {
      toggleCollabModal();
      return;
    }

    void _onClose();
  }, [toggleCollabModal, _onClose]);

  const handleRemoveErrors = useCallback(async (errors): Promise<void> => {
    const taskWarning = 'Warning, all drivers will be deleted';
    if (errors.includes(taskWarning)) {
      const shouldDeleteTasksConfirmation = window.confirm(errors);
      if (shouldDeleteTasksConfirmation) {
        try {
          await deleteModularObjects([objectCardData as ModularObject], true);
          await _onClose();
        } catch (err) {
          console.error(err.error);
          await handleRemoveErrors(err.error);
        }
      }
    } else {
      console.error(errors);
      addToastError(errors);
    }
  }, [objectCardData, _onClose]);

  const handleRemoveObject = useCallback(async (): Promise<void> => {
    const deleteConfirmation = window.prompt(
      'Are you sure you want to delete this?\nAll associated data will be removed forever.\nType \'DELETE\' below to confirm.',
    );
    if (deleteConfirmation === 'DELETE') {
      try {
        await deleteModularObjects([objectCardData as ModularObject]);
        await _onClose();

        apolloClient.cache.modify<QueryFieldPolicy>({
          fields: {
            getModularObjectByIDs(existingModularObjectRefs: Reference[], { readField }) {
              return existingModularObjectRefs.filter((ref) => {
                return readField('id', ref) !== objectCardData?.id;
              });
            },
            getTopLevelModularObjects(existingModularObjectRefs: Reference[], { readField }) {
              return existingModularObjectRefs.filter((ref) => {
                return readField('id', ref) !== objectCardData?.id;
              });
            },
          },
        });

        void apolloClient.refetchQueries({
          include: [GetTemplatesUsedByModularObjectsDocument, GetModularObjectsByTemplateIdDocument],
        });
      } catch (err) {
        if (err) await handleRemoveErrors(err.error);
      }
    } else if (deleteConfirmation?.toLowerCase() === 'delete') {
      addToastError(`You typed '${deleteConfirmation}'. Case matters!`);
      await handleRemoveObject();
    } else if (deleteConfirmation) {
      addToastError(
        `You typed '${deleteConfirmation}'.\nPlease type 'DELETE' (case-sensitive) if you would like to delete.`,
      );
      await handleRemoveObject();
    }
  }, [objectCardData, _onClose, apolloClient, handleRemoveErrors]);

  const handleDuplicateObject = useCallback(async (): Promise<void> => {
    try {
      const { data } = await duplicateObjects({
        variables: {
          ids: [objectCardData.id],
        },
      });

      if (!data?.duplicateModularObjects?.[0]) {
        throw new Error('Failed to duplicate object');
      }

      void apolloClient.refetchQueries({
        include: [GetTopLevelModularObjectsDocument],
      });

      addSaveSuccessToast(true, `Duplicated ${activeTemplate?.name ?? 'object'}.`);
    } catch (err) {
      console.error(err);
      addToastError(`Failed to duplicate ${activeTemplate?.name ?? 'object'}.\n${err.message ?? err}`);
    }
  }, [objectCardData, duplicateObjects, apolloClient, activeTemplate]);

  const pending = useMemo(
    () => objectCardData?.approvals?.filter(({ approvalType }) => approvalType === 'diff') ?? [],
    [objectCardData],
  );

  const requestedChanges = useMemo(() => {
    return uniq(pending.reduce((acc: string[], cur) => {
      if (cur.approvalType !== 'diff') {
        return acc;
      }

      return [
        ...acc,
        ...cur.requestedChanges.map((change) => `data.${change?.fieldId || ''}`),
      ];
    }, []));
  }, [pending]);

  useEffect(() => {
    // Add event listener to handle closing the card when 'esc' is pressed
    const handleEsc = (e): void => {
      if (e.key === 'Escape') {
        handleCloseCard(collabModalStateRef);
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [handleCloseCard]);

  return (
    <div data-testid='object-card' className='flex overflow-hidden w-full h-full'>
      <EditContextProvider
        canUserEdit={canUserEdit}
        isOwner={userPermission === 'Owner'}
        requestedChanges={[...new Set(requestedChanges)]}
      >
        <FormProvider {...methods}>
          <form
            data-testid='object-card-form'
            className='flex flex-col w-full'
          >
            <ObjectProvider
              activeModularObject={activeObject}
              activeTemplate={activeTemplate}
              isExternalLink={forExternalLinks}
            >
              <PrismaticProvider
                objectId={activeModularObjectId}
                templateType={activeTemplate?.type}
                isOwner={userPermission === 'Owner'}
              >
                <CollaborationColumn open={isCollaborationOpen} setIsOpen={toggleCollabModal} />
                <Header
                  handleClose={() => {
                    handleCloseCard(collabModalStateRef);
                  }}
                  handleDuplicate={handleDuplicateObject}
                  handleDelete={handleRemoveObject}
                  openCollaborationModal={toggleCollabModal}
                  open={isCollaborationOpen}
                  canDelete={canUserEdit}
                />
                <TaskListProvider object={objectCardData as ModularObject}>
                  <ListActionProvider hardwareId={objectCardData?.id}>
                    <ObjectCardScrollingContainer>
                      <ObjectCardContent />
                    </ObjectCardScrollingContainer>
                    <TaskListActions />
                  </ListActionProvider>
                </TaskListProvider>
              </PrismaticProvider>
            </ObjectProvider>
          </form>
        </FormProvider>
      </EditContextProvider>
      {objectCardData?.deactivated && (
        <div className='flex absolute top-0 right-0 bottom-0 left-0 z-50 justify-center items-center bg-white bg-opacity-40'>
          <DisabledObjectMessage
            objectId={activeModularObjectId}
            organizationId={objectCardData?.owner?.organizationId}
          />
        </div>
      )}
    </div>
  );
}

export default memo(ObjectCard);
