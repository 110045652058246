import type { Share, User } from '@/__generated__/types';
import CustomCheckbox from '@/components/modules/CustomCheckbox';
import Collaborator from '@/designSystemComponents/Collaborator';

import { Fragment } from 'react';

interface CollaboratorListItemProps {
  selected: Share[];
  user: Partial<User>;
  handleCheck: (user: User) => void;
}

export function CollaboratorListItem ({ selected, user, handleCheck }: CollaboratorListItemProps) {
  return (
    <div className='flex gap-3 items-center' key={user?.id}>
      <CustomCheckbox
        selected={Boolean(selected.find(selectedUser => selectedUser?.user?.id === user?.id))}
        onCheck={() => handleCheck(user as User)}
        className='cursor-pointer h-[16px] w-[16px]'
      />
      <Collaborator user={user as User} withTitle />
    </div>
  );
}

interface CollaboratorListProps {
  collaborators: Array<Partial<User>>;
  selected: Share[];
  addCollaborator: (user: User) => void;
}

export default function CollaboratorList ({ collaborators, selected, addCollaborator }: CollaboratorListProps) {
  return (
    <Fragment>
      {collaborators.map((user) => (
        <CollaboratorListItem
          key={user?.id}
          selected={selected}
          user={user}
          handleCheck={addCollaborator}
        />
      ))}
    </Fragment>
  );
}
