import { useAppDispatch } from '@/state/hooks';
import { logout as logoutAction } from '@/state/slices/session.slice';
import { env } from '@/util/env.utils';
import { callLogout } from '@/util/requests.functions';
import * as amplitude from '@amplitude/analytics-browser';
import { useApolloClient } from '@apollo/client';
import { deleteCookie } from 'cookies-next';
import { useRouter } from 'next/router';

export const useLogout = () => {
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const router = useRouter();

  const logout = async () => {
    try {
      await callLogout();
    } catch (e) {
      console.error('Error logging out');
      console.error(e);
    }

    // Delete cookie first so that if any redux/apollo requests happen they won't have a cookie and it fails fast
    deleteCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'), {
      domain: env('NEXT_PUBLIC_COOKIE_DOMAIN'),
    });
    dispatch(logoutAction());
    amplitude.reset();
    await client.resetStore();

    await router.push('/login');
  };

  return {
    logout,
  };
};
