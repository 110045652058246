import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import { FullUserFragmentDoc } from '../fragments/user.fragment.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type RefreshTokenQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RefreshTokenQuery = { __typename?: 'Query', refreshToken: { __typename?: 'LoginResponse', token: string | null, expire: string | null, user: { __typename?: 'User', id: string, email: string | null, address: string | null, title: string | null, firstName: string | null, lastName: string | null, organizationId: string | null, phoneNumber: string | null, profileImage: string | null, role: Types.UserRoles | null, lastLoggedIn: any | null, onboardingStep: string | null, onboardingCompleted: any | null, mfaEnabled: boolean | null, organization: { __typename?: 'Organization', id: string, name: string | null, logo: string | null, logoHorizontal: string | null, maxUsers: number | null, organizationTypes: Array<string | null> | null, invitationsRemaining: number | null, hardware: Array<string | null> | null, createdAt: any | null, modifiedAt: any | null, createdById: string | null, resourceCostUnit: Types.ResourceCostUnit | null, tld: string | null } | null, acknowledgements: { __typename?: 'Acknowledgements', termsOfUse: boolean } | null, filters: Array<{ __typename?: 'Filter', id: string, name: string } | null> | null } | null } };


export const RefreshTokenDocument = gql`
    query RefreshToken {
  refreshToken {
    token
    expire
    user {
      ...FullUser
    }
  }
}
    ${FullUserFragmentDoc}`;

/**
 * __useRefreshTokenQuery__
 *
 * To run a query within a React component, call `useRefreshTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenQuery(baseOptions?: Apollo.QueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
      }
export function useRefreshTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
        }
export function useRefreshTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
        }
export type RefreshTokenQueryHookResult = ReturnType<typeof useRefreshTokenQuery>;
export type RefreshTokenLazyQueryHookResult = ReturnType<typeof useRefreshTokenLazyQuery>;
export type RefreshTokenSuspenseQueryHookResult = ReturnType<typeof useRefreshTokenSuspenseQuery>;
export type RefreshTokenQueryResult = Apollo.QueryResult<RefreshTokenQuery, RefreshTokenQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRefreshTokenQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ refreshToken })
 *   )
 * })
 */
export const mockRefreshTokenQuery = (resolver: Parameters<typeof graphql.query<RefreshTokenQuery, RefreshTokenQueryVariables>>[1]) =>
  graphql.query<RefreshTokenQuery, RefreshTokenQueryVariables>(
    'RefreshToken',
    resolver
  )
