import type * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type ModularObjectListItemFragment = { __typename?: 'ModularObject', id: string, data: any | null, deactivated: boolean | null, name: string | null, ownerId: string | null, owner: { __typename?: 'User', organizationId: string | null } | null, template: { __typename?: 'Template', name: string | null } | null };

export type GetModularObjectsByTemplateIdQueryVariables = Types.Exact<{
  templateId: Types.Scalars['UUID']['input'];
}>;


export type GetModularObjectsByTemplateIdQuery = { __typename?: 'Query', getModularObjectsByTemplateId: Array<{ __typename?: 'ModularObject', id: string, data: any | null, deactivated: boolean | null, name: string | null, ownerId: string | null, owner: { __typename?: 'User', organizationId: string | null } | null, template: { __typename?: 'Template', name: string | null } | null } | null> };

export const ModularObjectListItemFragmentDoc = gql`
    fragment ModularObjectListItem on ModularObject {
  id
  data
  deactivated
  name
  ownerId
  owner {
    organizationId
  }
  template {
    name
  }
}
    `;
export const GetModularObjectsByTemplateIdDocument = gql`
    query GetModularObjectsByTemplateId($templateId: UUID!) {
  getModularObjectsByTemplateId(templateId: $templateId) {
    ...ModularObjectListItem
  }
}
    ${ModularObjectListItemFragmentDoc}`;

/**
 * __useGetModularObjectsByTemplateIdQuery__
 *
 * To run a query within a React component, call `useGetModularObjectsByTemplateIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModularObjectsByTemplateIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModularObjectsByTemplateIdQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGetModularObjectsByTemplateIdQuery(baseOptions: Apollo.QueryHookOptions<GetModularObjectsByTemplateIdQuery, GetModularObjectsByTemplateIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModularObjectsByTemplateIdQuery, GetModularObjectsByTemplateIdQueryVariables>(GetModularObjectsByTemplateIdDocument, options);
      }
export function useGetModularObjectsByTemplateIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModularObjectsByTemplateIdQuery, GetModularObjectsByTemplateIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModularObjectsByTemplateIdQuery, GetModularObjectsByTemplateIdQueryVariables>(GetModularObjectsByTemplateIdDocument, options);
        }
export function useGetModularObjectsByTemplateIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetModularObjectsByTemplateIdQuery, GetModularObjectsByTemplateIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModularObjectsByTemplateIdQuery, GetModularObjectsByTemplateIdQueryVariables>(GetModularObjectsByTemplateIdDocument, options);
        }
export type GetModularObjectsByTemplateIdQueryHookResult = ReturnType<typeof useGetModularObjectsByTemplateIdQuery>;
export type GetModularObjectsByTemplateIdLazyQueryHookResult = ReturnType<typeof useGetModularObjectsByTemplateIdLazyQuery>;
export type GetModularObjectsByTemplateIdSuspenseQueryHookResult = ReturnType<typeof useGetModularObjectsByTemplateIdSuspenseQuery>;
export type GetModularObjectsByTemplateIdQueryResult = Apollo.QueryResult<GetModularObjectsByTemplateIdQuery, GetModularObjectsByTemplateIdQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetModularObjectsByTemplateIdQuery((req, res, ctx) => {
 *   const { templateId } = req.variables;
 *   return res(
 *     ctx.data({ getModularObjectsByTemplateId })
 *   )
 * })
 */
export const mockGetModularObjectsByTemplateIdQuery = (resolver: Parameters<typeof graphql.query<GetModularObjectsByTemplateIdQuery, GetModularObjectsByTemplateIdQueryVariables>>[1]) =>
  graphql.query<GetModularObjectsByTemplateIdQuery, GetModularObjectsByTemplateIdQueryVariables>(
    'GetModularObjectsByTemplateId',
    resolver
  )
