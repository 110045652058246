import type { HardwareIntegration, Notification, User, UserPreferences } from '@/__generated__/types';
import type { PrismaticUser } from '@/util/prismatic';
import { createSlice } from '@reduxjs/toolkit';
import { set, unset } from 'lodash';

type NotificationsRecord = Record<
  'approval' | 'deployer' | 'milestone' | 'mission' | 'payload' | 'spacecraft' | 'vehicle',
  Record<string, Notification>
>;
export interface InitialSessionState {
  user?: User;
  userPreferences?: UserPreferences;
  isLoggedOut?: boolean;
  modal?: { isOpen: boolean };
  notifications?: NotificationsRecord;
  isSubscriptionActive?: boolean;
  organizationAdmin?: string;
  fetched?: Record<string, boolean>;
  modularObjectModal?: {
    isOpen?: boolean;
    modularObjectId?: string;
    templateId?: string;
    draftIntegrations?: HardwareIntegration[];
    createdFromId?: string;
    createdFromTemplateId?: string;
    asParent?: boolean;
    asTask?: boolean;
    isDirty?: boolean;
    isDependency?: boolean;
    isBlockingDependency?: boolean;
  };
  prismaticUser?: PrismaticUser;
}

export const initialState: InitialSessionState = {
  user: null,
  userPreferences: {
    id: null,
    userId: null,
    preferences: {},
  },
  isLoggedOut: true,
  modal: {
    isOpen: false,
  },
  notifications: {
    approval: {},
    deployer: {},
    milestone: {},
    mission: {},
    payload: {},
    spacecraft: {},
    vehicle: {},
  },
  isSubscriptionActive: true,
  organizationAdmin: '',
  fetched: {},
  modularObjectModal: {
    isOpen: false,
    modularObjectId: null,
    templateId: null,
    draftIntegrations: [],
    createdFromId: null,
    createdFromTemplateId: null,
    asParent: false,
    asTask: false,
    isDirty: false,
    isDependency: false,
    isBlockingDependency: false,
  },
  prismaticUser: null,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    login(state) {
      state.isLoggedOut = false;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setUserPreferences(state, action) {
      state.userPreferences = action.payload;
    },
    addUserPreference(state, action) {
      set(state, [action.payload.key], action.payload.value);
    },
    removeUserPreference(state, action) {
      unset(state, [action.payload.key]);
    },
    openModal(state, action) {
      state.modal = {
        isOpen: true,
        ...action.payload,
      };
    },
    closeModal(state) {
      state.modal = {
        isOpen: false,
      };
    },
    setModularObjectModal(state, action) {
      state.modularObjectModal = {
        ...state.modularObjectModal,
        ...action.payload,
      };
    },
    openModularObjectModal(state, action) {
      state.modularObjectModal = {
        ...state.modularObjectModal,
        draftIntegrations: [],
        isOpen: true,
        ...action.payload,
      };
    },
    closeModularObjectModal(state) {
      state.modularObjectModal = initialState.modularObjectModal;
    },
    addNotifications(state, action) {
      action.payload.forEach((notification) => {
        const subPath = notification.notificationType === 'approval'
          ? 'approval'
          : notification.externalType;
        set(
          state,
          ['notifications', subPath, notification.externalId],
          notification,
        );
      });
    },
    removeNotifications(state, action) {
      action.payload.forEach((notification) => {
        const subPath = notification.notificationType === 'approval'
          ? 'approval'
          : notification.externalType;
        unset(state, ['notifications', subPath, notification.externalId]);
      });
    },
    setIsSubscriptionActive(state, action) {
      state.isSubscriptionActive = Boolean(action.payload);
    },
    setOrganizationAdmin(state, action) {
      state.organizationAdmin = action.payload;
    },
    setFetchedState(state, action) {
      state.fetched[action.payload] = true;
    },
    setPrismaticUser(state, action) {
      state.prismaticUser = action.payload;
    },
  },
});

export const {
  logout,
  login,
  setUser,
  setUserPreferences,
  addUserPreference,
  removeUserPreference,
  openModal,
  closeModal,
  openModularObjectModal,
  closeModularObjectModal,
  setModularObjectModal,
  addNotifications,
  removeNotifications,
  setIsSubscriptionActive,
  setOrganizationAdmin,
  setFetchedState,
  setPrismaticUser,
} = sessionSlice.actions;
export const { reducer: sessionReducer } = sessionSlice;
