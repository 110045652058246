import { useGetApprovalsQuery } from '@/components/tables/getApprovals.generated';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useGetRecentActivityCountByUserIdQuery } from '@/state/getRecentActivityCountByUserId.generated';
import metrics from '@/util/metrics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCheckDouble } from '@fortawesome/sharp-solid-svg-icons';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import IconWithCounter from './IconWithCounter';
import UserMenu from './UserMenu';

interface SidenavHeaderProps {
  expandSidenav: () => void;
  isExpanded: boolean;
}

export default function SidenavHeader ({ expandSidenav, isExpanded }: Readonly<SidenavHeaderProps>): JSX.Element {
  const router = useRouter();
  const user = useLoggedInUser();

  const { data: activityCountData } = useGetRecentActivityCountByUserIdQuery({
    variables: { id: user?.id },
    skip: !user?.id,
    pollInterval: 60000,
  });

  const { data: approvalsData } = useGetApprovalsQuery({
    pollInterval: 60000,
  });

  const { headerText, notificationCount } = useMemo(() => {
    const notificationCount = activityCountData?.getRecentActivityCountByUserId;
    const headerText = !notificationCount
      ? 'Integrate'
      : `(${notificationCount < 100 ? notificationCount : '99+'}) Integrate`;

    return {
      headerText,
      notificationCount,
    };
  }, [activityCountData]);
  const approvalCount = useMemo(() => approvalsData?.getApprovals?.length, [approvalsData]);

  const handleUserMenuClick = () => {
    expandSidenav();
    metrics.track('sidenav - user menu clicked');
  };

  const handleNotificationsClick = async () => {
    metrics.track('sidenav - notifications clicked');
    await router.push('/recent-activity');
  };

  const handleApprovalsClick = async () => {
    metrics.track('sidenav - approvals clicked');
    await router.push('/approvals');
  };

  return (
    <>
      <Head>
        <title>{headerText}</title>
      </Head>
      <div className='flex @expanded/sidenav:justify-between flex-col @expanded/sidenav:flex-row items-center gap-[16px]'>
        <UserMenu
          onClick={handleUserMenuClick}
        />
        <div className='flex gap-[16px] @expanded/sidenav:gap-[8px] flex-col @expanded/sidenav:flex-row text-white text-[16px] @expanded/sidenav:h-[24px]'>
          <IconWithCounter name='Notifications' count={notificationCount} isExpanded={isExpanded}>
            <button onClick={handleNotificationsClick} className='flex'>
              <FontAwesomeIcon icon={faBell} />
            </button>
          </IconWithCounter>
          <IconWithCounter name='Approvals' count={approvalCount} isExpanded={isExpanded}>
            <button onClick={handleApprovalsClick} className='flex'>
              <FontAwesomeIcon icon={faCheckDouble} />
            </button>
          </IconWithCounter>
        </div>
      </div>
    </>
  );
}
