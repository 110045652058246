import dynamic from 'next/dynamic';
import Color from '../types/Color.enum';
import * as blackLoader from './integrate-loader-black.json';
import * as primaryLoader from './integrate-loader-primary.json';
import * as secondaryLoader from './integrate-loader-secondary.json';
import * as tertiaryLoader from './integrate-loader-tertiary.json';
import * as whiteLoader from './integrate-loader-white.json';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

const ANIMATION_DATA = {
  primary: primaryLoader,
  secondary: secondaryLoader,
  tertiary: tertiaryLoader,
  white: whiteLoader,
  black: blackLoader,
};

export interface LoaderProps {
  className?: string;
  color?: Color;
}

export function Loader ({ className, color = Color.PRIMARY }: LoaderProps): JSX.Element {
  return <Lottie id='integrate-loader' animationData={ANIMATION_DATA[color]} className={className} />;
}
