import type { SharesInput, User } from '@/__generated__/types';
import cx from 'classnames';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { useCollabDiscoveryContext } from './CollabDiscovery.context';
import CollaboratorRoleDropdown, { StaticRole } from './CollaboratorRoleDropDown';
import { DropdownOption } from './hooks/useDropdownOptionMap';
import { useDeleteCollaboratorMutation } from './mutations/deleteShares.generated';
import { useUpdateCollaboratorsMutation } from './mutations/updateShares.generated';

import { GetModularObjectByIdDocument } from '@/components/modals/ObjectSelectionModal/getModularObjectById.generated';
import { GetTopLevelModularObjectsDocument } from '@/components/Schedule/queries/getTopLevelModularObjects.generated';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { Button } from '@/designSystemComponents/Button';
import Collaborator from '@/designSystemComponents/Collaborator';
import { useScrollWithShadow } from '@/hooks/useScrollWithShadow';
import { useAppDispatch } from '@/state/hooks';
import { closeModularObjectModal } from '@/state/slices/session.slice';
import { useState } from 'react';
import { useAddNewCollaboratorsMutation } from './mutations/addShares.generated';
import { useUpdateModularObjectMutation } from './mutations/updateModularObject.generated';
import PendingInvite from './PendingInvite';

interface CollaboratorItemProps {
  user: User;
  role: DropdownOption;
  handleRoleChange: (shareId: string, user: User) => (roleValue: DropdownOption) => void;
  isAnonymousUser: boolean;
  currentUserId: string;
  currentUserRole: string;
  id: string;
  collaborators: Array<
    {
      __typename?: 'Share';
      id: string;
      role: string;
      user: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        profileImage: string;
        organizationId: string;
      };
    } | { __typename?: 'AnonymousUser'; id: string; email: string }
  >;
  roleOptionMap: Map<string, DropdownOption[]>;
}

function CollaboratorItem (props: CollaboratorItemProps) {
  const {
    user,
    role,
    handleRoleChange,
    isAnonymousUser,
    currentUserId,
    currentUserRole,
    id,
    collaborators,
    roleOptionMap,
  } = props;

  if (isAnonymousUser) return null;

  return (
    <>
      <Collaborator user={user} />
      <CollaboratorRoleDropdown
        autoRotate={collaborators.length > 4}
        isNotEditor={(user?.id !== currentUserId && currentUserRole === DropdownOption.Viewer) || !currentUserRole}
        value={role}
        options={roleOptionMap.get(currentUserRole)}
        onChange={handleRoleChange(id, user)}
      />
    </>
  );
}

interface ConfirmCollaboratorRemovalProps {
  shareUpdate: {
    id: string;
    role: DropdownOption;
    externalType: string;
    externalId: string;
    userId: string;
    user: User;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUserToRemove: (value: any) => void;
  currentUserId: string;
}

function ConfirmCollaboratorRemoval ({
  shareUpdate,
  setUserToRemove,
  currentUserId,
}: ConfirmCollaboratorRemovalProps) {
  const dispatch = useAppDispatch();
  const [removeCollaborators] = useDeleteCollaboratorMutation();

  const isCurrentUserBeingRemoved = shareUpdate.userId === currentUserId;

  const handleConfirm = async () => {
    try {
      setUserToRemove(null);
      await removeCollaborators({
        variables: {
          input: [shareUpdate],
        },
        onQueryUpdated: (observableQuery) => {
          void observableQuery.refetch();
          if (isCurrentUserBeingRemoved) dispatch(closeModularObjectModal());
        },
        refetchQueries: isCurrentUserBeingRemoved
          ? [GetTopLevelModularObjectsDocument, GetModularObjectByIdDocument]
          : [],
      });
      addToastSuccess('Collaborator removed');
    } catch (error) {
      console.error(error);
      addToastError('Failed to remove collaborator');
    }
  };

  return (
    <div className='flex flex-col gap-4 justify-center items-center h-[230px]'>
      <p className='effra-14'>Are you sure you want to remove this collaborator?</p>
      <div className='flex gap-4'>
        <Button
          figmaProps={{
            size: 'small',
            style: 'outline',
          }}
          onClick={() => {
            setUserToRemove(null);
          }}
        >
          Cancel
        </Button>
        <Button figmaProps={{ size: 'small', style: 'fill' }} onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
}

type ExtendedUser = User & {
  isExternal?: boolean;
  isAnonymousUser?: boolean;
  firstName: string;
  lastName: string;
};

interface ConfirmOwnerShipTransferProps {
  shareUpdate: {
    id: string;
    role: DropdownOption;
    externalType: string;
    externalId: string;
    userId: string;
    user: User;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setOwnerToTransfer: (value: any) => void;
  objectOwner: ExtendedUser;
}

function ConfirmOwnerShipTransfer ({ shareUpdate, setOwnerToTransfer, objectOwner }: ConfirmOwnerShipTransferProps) {
  const [addNewCollaborators] = useAddNewCollaboratorsMutation();
  const [updateModularObject] = useUpdateModularObjectMutation({
    onQueryUpdated: (observableQuery) => {
      void observableQuery.refetch();
    },
  });

  const handleConfirm = async () => {
    try {
      setOwnerToTransfer(null);
      await addNewCollaborators({
        variables: {
          input: [{
            ...shareUpdate,
            id: uuidv5(`ownershipTransfer-${objectOwner?.id}`, uuidv4()),
            role: DropdownOption.Editor,
            user: {
              id: objectOwner?.id,
              firstName: objectOwner?.firstName,
              lastName: objectOwner?.lastName,
              email: objectOwner?.email,
              organizationId: objectOwner?.organizationId,
              profileImage: objectOwner?.profileImage,
            } as SharesInput['user'],
            userId: objectOwner?.id,
          }],
        },
      });
      await updateModularObject({
        variables: {
          input: {
            diffs: [{
              diffType: 'updated',
              externalID: shareUpdate.externalId,
              externalType: 'modular_object',
              diff: {
                displayNames: { ownerId: 'ownerId' },
                to: { ownerId: shareUpdate.userId },
              },
              fromMigration: false,
            }],
          },
        },
      });
      addToastSuccess('Ownership transferred');
    } catch (error) {
      console.error(error);
      addToastError('Failed to transfer ownership');
    }
  };

  return (
    <div className='flex flex-col gap-4 justify-center items-center h-[230px]'>
      <p className='effra-14'>Are you sure you want to transfer ownership of this object?</p>
      <div className='flex gap-4'>
        <Button
          figmaProps={{
            size: 'small',
            style: 'outline',
          }}
          onClick={() => {
            setOwnerToTransfer(null);
          }}
        >
          Cancel
        </Button>
        <Button figmaProps={{ size: 'small', style: 'fill' }} onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
}

interface ConfirmExternalUserSubmissionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setExternalUserAddition: (value: any) => void;
  submitExternalUsers: () => void;
}

function ConfirmExternalUserSubmission (
  { setExternalUserAddition, submitExternalUsers }: ConfirmExternalUserSubmissionProps,
) {
  function handleConfirmSubmission () {
    setExternalUserAddition(false);
    submitExternalUsers();
  }

  return (
    <div className='flex flex-col gap-4 justify-center items-center h-[230px]'>
      <p className='flex gap-1 text-center effra-14'>
        <i className='fa-sharp fa-solid fa-triangle-exclamation text-warning' />
        <span>You are attempting to share this item with someone outside your company.</span>
      </p>
      <div className='flex gap-4'>
        <Button
          figmaProps={{
            size: 'small',
            style: 'outline',
          }}
          onClick={() => {
            setExternalUserAddition(false);
          }}
        >
          Cancel
        </Button>
        <Button figmaProps={{ size: 'small', style: 'fill' }} onClick={handleConfirmSubmission}>
          Confirm
        </Button>
      </div>
    </div>
  );
}

interface CollaboratorsListProps {
  objectId: string;
}
export default function CollaboratorsList ({ objectId }: CollaboratorsListProps) {
  const {
    objectOwner,
    currentUserRole,
    currentUserId,
    initialUsers: collaborators,
    externalUserAddition,
    setExternalUserAddition,
    handleSubmitSharedUsers,
  } = useCollabDiscoveryContext();

  const [updateCollaborator] = useUpdateCollaboratorsMutation();
  const { boxShadow: { showTop, showBottom }, onScrollHandler } = useScrollWithShadow();

  const [userToRemove, setUserToRemove] = useState(null);
  const [ownerToTransfer, setOwnerToTransfer] = useState(null);

  const handleRoleChange = (shareId, user) => {
    return async (roleValue: DropdownOption) => {
      try {
        const shareUpdate = {
          id: shareId,
          role: roleValue,
          externalType: 'modular_object',
          externalId: objectId,
          userId: user?.id,
          user: {
            id: user?.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            organizationId: user?.organizationId,
            profileImage: user?.profileImage,
            fullName: null,
            title: null,
          },
        };

        if (roleValue === DropdownOption.Owner) {
          setOwnerToTransfer(shareUpdate);
        } else if (roleValue === DropdownOption.Remove) {
          setUserToRemove(shareUpdate);
        } else {
          await updateCollaborator({
            variables: {
              input: [shareUpdate] as SharesInput[],
            },
          });
          addToastSuccess('Collaborator role updated');
        }
      } catch (error) {
        console.error(error);
        addToastError('Failed to update collaborator role');
      }
    };
  };

  const { Owner, Editor, Viewer, Remove } = DropdownOption;

  const roleOptionMap = new Map();
  roleOptionMap.set('Owner', [Owner, Editor, Viewer, Remove]);
  roleOptionMap.set('Editor', [Editor, Viewer, Remove]);
  roleOptionMap.set('Viewer', [Viewer, Remove]);

  if (externalUserAddition) {
    return (
      <ConfirmExternalUserSubmission
        setExternalUserAddition={setExternalUserAddition}
        submitExternalUsers={handleSubmitSharedUsers}
      />
    );
  }

  if (userToRemove) {
    return (
      <ConfirmCollaboratorRemoval
        shareUpdate={userToRemove}
        setUserToRemove={setUserToRemove}
        currentUserId={currentUserId}
      />
    );
  }

  if (ownerToTransfer) {
    return (
      <ConfirmOwnerShipTransfer
        shareUpdate={ownerToTransfer}
        setOwnerToTransfer={setOwnerToTransfer}
        objectOwner={objectOwner as ExtendedUser}
      />
    );
  }

  if (objectOwner?.id === currentUserId) {
    (objectOwner as ExtendedUser).firstName = 'You';
    (objectOwner as ExtendedUser).lastName = '';
  }

  return (
    <div className='relative z-5' data-testid='collaborators-list'>
      <div
        className={cx('absolute h-[50px] -right-4 -left-6 -top-8 bg-white blur', {
          'hidden': !showTop || collaborators.length < 5,
        })}
      />
      <div
        className={cx('absolute h-[50px] -right-4 -left-6 -bottom-8 bg-white blur', {
          'hidden': !showBottom || collaborators.length < 5,
        })}
      />
      <div
        className={cx('flex flex-col gap-2 max-h-[230px]', {
          'overflow-y-scroll': collaborators.length > 4,
        })}
        onScroll={onScrollHandler}
      >
        <div className='flex justify-between items-center'>
          {/* @ts-expect-error - type data needs to be fixed */}
          <Collaborator user={objectOwner} />
          <StaticRole role={DropdownOption.Owner} />
        </div>
        {/* @ts-expect-error - type data needs to be fixed */}
        {collaborators.map(({ id, user, role, __typename, email }) => {
          const isAnonymousUser = __typename === 'AnonymousUser';
          if (user?.id === objectOwner?.id) {
            user.firstName = 'You';
            user.lastName = '';
          }
          return (
            <div key={user?.id} className='flex justify-between items-center'>
              <PendingInvite email={email} isAnonymousUser={isAnonymousUser} />
              <CollaboratorItem
                {...{
                  user,
                  role,
                  handleRoleChange,
                  isAnonymousUser,
                  currentUserId,
                  currentUserRole,
                  id,
                  collaborators,
                  roleOptionMap,
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
