import type { File } from '@/__generated__/types';
import { useEditContext } from '@/components/cards';
import { GetHistoryUpdatesForObjectIdDocument } from '@/components/cards/UpdatesColumn/getHistoryUpdatesForObjectId.generated';
import { uploadNewFile } from '@/components/common/Files/utils';
import { buildUpdateObjectDiffPayloadForTemplateImage } from '@/components/common/ModularObject/utils';
import { Loader } from '@/components/loader';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { postFiles } from '@/util/requests.functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/sharp-regular-svg-icons';
import { type ChangeEvent, useCallback, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { useAddTemplateImageMutation } from './AddTemplateImage.generated';

export default function ImageUpload (): JSX.Element {
  const loggedInUser = useLoggedInUser();
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { canUserEdit } = useEditContext();
  const { objectCardData } = useObjectCardContext();

  const { control } = useFormContext();
  const [addTemplateImage] = useAddTemplateImageMutation();

  const updateTemplateImage = useCallback(async (newFile: File) => {
    await addTemplateImage({
      variables: {
        input: {
          diffs: [
            buildUpdateObjectDiffPayloadForTemplateImage({
              file: newFile,
              objectId: objectCardData.id,
            }),
          ],
        },
      },
      refetchQueries: [GetHistoryUpdatesForObjectIdDocument],
      onCompleted: async (data) => {
        const latestFiles = data.updateModularObject[0].files ?? [];

        const isSaved = latestFiles.some((f) => f.id === newFile.id);

        const SUCCESS_MESSAGE = isSaved
          ? 'Successfully saved item image'
          : 'Successfully requested approval for adding item image';

        addToastSuccess(SUCCESS_MESSAGE);
      },
      onError: () => addToastError('Failed to add file, please try again'),
    });
  }, [addTemplateImage, objectCardData.id]);

  const onImageSelect = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    setIsLoading(true);
    for await (const file of Array.from(event.target.files)) {
      const response = await uploadNewFile(file);
      const responseFile = { ...response?.file, signedURL: response?.signed };
      const newFileId: string = uuidv5(`${responseFile.name}-${uuidv4()}`, loggedInUser?.id);
      const newFile = {
        ...responseFile,
        id: newFileId,
        isPending: true,
        externalId: objectCardData.id,
        externalType: 'modular_object',
      };

      await postFiles([newFile]);

      await updateTemplateImage(newFile);
    }
    inputRef.current.value = null;
    setIsLoading(false);
  };

  const openUploadDialog = (): void => {
    inputRef.current?.click();
  };

  if (!canUserEdit) return null;
  return (
    <Controller
      name='imageId'
      control={control}
      render={({ field: { value, onChange, ...field } }) => (
        <>
          <button
            type='button'
            onClick={openUploadDialog}
            className='absolute top-0 left-0 w-full h-full opacity-0 transition-all hover:opacity-100 bg-white/70'
          >
            <div className='flex justify-center items-center link-primary'>
              <FontAwesomeIcon icon={faImage} />
              Upload
            </div>
          </button>
          {isLoading && (
            <div className='flex absolute top-0 left-0 justify-center items-center w-full h-full transition-all bg-white/70'>
              <Loader className='items-center w-20 h-20' />
            </div>
          )}
          <input
            data-testid='image-upload-input'
            {...field}
            ref={inputRef}
            value={value?.name}
            onChange={onImageSelect}
            className='hidden'
            accept={'image/*'}
            type='file'
          />
        </>
      )}
    />
  );
}
