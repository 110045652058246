import type { CreateModularObjectCardFragmentFragment } from '@/components/common/CreateNewObjectCard/CreateModularObject.generated';
import { useAppSelector } from '@/state/hooks';
import getIcon from '@/util/getIcon';
import MegaModal from '../MegaModal/MegaModal';
import MegaModalHeader from '../MegaModal/MegaModalHeader/MegaModalHeader';
import CreateNewTaskRow from './CreateNewTaskRow';

interface CreateNewTaskModalProps {
  hardwareId?: string;
  afterObjectCreationSave?: (modularObject: CreateModularObjectCardFragmentFragment) => void;
}

export default function CreateNewTaskModal ({
  hardwareId,
  afterObjectCreationSave,
}: Readonly<CreateNewTaskModalProps>): JSX.Element {
  const { templates } = useAppSelector(state => state);

  const taskNames = Object.values(templates)
    .filter(item => item?.type === 'task' && item?.id !== 'd5ce3c7b-4776-47e0-aa14-796f3dd33784')
    .map(item => item?.name);

  return (
    <MegaModal className='w-screen max-w-[600px]'>
      <MegaModalHeader title='Select a Driver Type' />
      {taskNames.map((name: string) => (
        <CreateNewTaskRow
          key={`create-new-task-list-item-${name}`}
          taskName={name}
          icon={getIcon(name?.toLowerCase())}
          hardwareId={hardwareId}
          afterObjectCreationSave={afterObjectCreationSave}
        />
      ))}
    </MegaModal>
  );
}
