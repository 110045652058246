import type { User } from '@/__generated__/types';
import { Button } from '@/designSystemComponents/Button';
import { memo } from 'react';
import { useCollabDiscoveryContext } from './CollabDiscovery.context';
import CollaboratorRoleDropdown from './CollaboratorRoleDropDown';
import { DropdownOption } from './hooks/useDropdownOptionMap';
import UserSearchBar from './UserSearchBar';

type ExtendedUser = User & {
  isExternal?: boolean;
  isAnonymousUser?: boolean;
};

function CollaboratorDiscoverySearch () {
  const { Editor, Viewer } = DropdownOption;
  const collaboratorContext = useCollabDiscoveryContext();

  const { currentUserRole, handleSubmitSharedUsers, setRole, setExternalUserAddition, addedUsers } =
    collaboratorContext;

  if (currentUserRole === Viewer || !currentUserRole) return null;

  function handleSubmitUsers () {
    if (addedUsers.some((user: ExtendedUser) => user.isExternal || user.isAnonymousUser)) {
      setExternalUserAddition(true);
    } else {
      handleSubmitSharedUsers();
    }
  }
  return (
    <div id='collab-discovery-and-sharing-tools' className='flex gap-4 pb-8'>
      {/* @ts-expect-error - type data needs to be fixed */}
      <UserSearchBar {...collaboratorContext} disableAnonymousUsers={false} />
      <CollaboratorRoleDropdown
        value={DropdownOption.Viewer}
        onChange={setRole}
        options={[Editor, Viewer]}
      />
      <Button
        className='w-[70px] !h-[32px] rounded-[2px]'
        figmaProps={{
          size: 'small',
          style: 'fill',
        }}
        onClick={handleSubmitUsers}
      >
        share
      </Button>
    </div>
  );
}

export default memo(CollaboratorDiscoverySearch);
