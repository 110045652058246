import type { HardwareIntegration } from '@/__generated__/types';
import { Button } from '@/components/Button/Button';
import type { CreateModularObjectCardFragmentFragment } from '@/components/common/CreateNewObjectCard/CreateModularObject.generated';
import type { GetObjectCardObjectQuery } from '@/components/common/ModularObject/Card/GetObjectCardObject.generated';
import { useObjectCardScrollContext } from '@/components/common/ModularObject/Card/ObjectCardScroll.context';
import HeaderName from '@/components/common/Tasks/TaskSection/HeaderName';
import LoadingSpinner from '@/components/common/Tasks/TaskSection/LoadingSpinner';
import InfoTooltip from '@/components/tooltip/InfoTooltip';
import { useDebounce } from '@/hooks/useDebounce';
import { useModal } from '@/hooks/useModal';
import cx from 'classnames';
import { createRef, type Ref, useCallback, useEffect, useMemo, useState } from 'react';
import { useGetAddToParentModalResultsQuery } from '../AddToParentModal/GetAddToParentModalResults.generated';
import CreateNewObjectModal, { Filter } from '../CreateNewObjectModal/CreateNewObjectModal';
import MegaModal from '../MegaModal/MegaModal';
import MegaModalEmptyList from '../MegaModal/MegaModalEmptyList/MegaModalEmptyList';
import MegaModalHeader from '../MegaModal/MegaModalHeader/MegaModalHeader';
import MegaModalSearchInput from '../MegaModal/MegaModalSearchInput/MegaModalSearchInput';
import ObjectListRow from './ObjectListRow/ObjectListRow';

export interface AddNewObjectModalProps {
  modularObject: GetObjectCardObjectQuery['getModularObjectByIDs'][0];
  onSubmit: (integrations: HardwareIntegration[]) => void;
  disableCreateObject?: boolean;
  disableCreateObjectMessage?: string;
  preselectedId?: string;
  afterObjectCreationSave?: (modularObject: CreateModularObjectCardFragmentFragment) => void;
}

export default function AddNewObjectModal ({
  modularObject,
  onSubmit,
  disableCreateObject = false,
  disableCreateObjectMessage,
  preselectedId,
  afterObjectCreationSave,
}: Readonly<AddNewObjectModalProps>): JSX.Element {
  const inputRef: Ref<HTMLInputElement> = createRef();
  const tableRef: Ref<HTMLDivElement> = createRef();
  const { showModal } = useModal();
  const { scrollToId } = useObjectCardScrollContext();
  const { data, loading: isLoadingResults } = useGetAddToParentModalResultsQuery({
    fetchPolicy: 'network-only',
    variables: { id: modularObject.id },
  });

  const resultList = useMemo(() => {
    if (!data?.getPossibleParentsForModularObject) {
      return [];
    }

    if (preselectedId) {
      return data.getPossibleParentsForModularObject.sort((a, b) =>
        (a.id === preselectedId) ? -1 : b.id === preselectedId ? 1 : 0
      );
    }

    return data.getPossibleParentsForModularObject;
  }, [data?.getPossibleParentsForModularObject, preselectedId]);

  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState(preselectedId ? [preselectedId] : []);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    const tableWrapper = tableRef.current;
    if (tableWrapper?.scrollHeight > tableWrapper?.clientHeight) {
      setHasScrollbar(true);
    } else {
      setHasScrollbar(false);
    }
  }, []);

  useEffect(() => {
    inputRef?.current?.focus();
    setSearchTerm('');
  }, []);

  const filteredResultList = useMemo(() => {
    return resultList.filter((result) => {
      return result.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
        result.parent?.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
    });
  }, [resultList, debouncedSearchTerm]);

  const handleNewClick = useCallback(() => {
    showModal(
      <CreateNewObjectModal
        createdFromId={modularObject.id}
        createdFromTemplateId={modularObject.templateId}
        showFilters={false}
        filteredBy={Filter.Builds}
        afterObjectCreationSave={afterObjectCreationSave}
      />,
      {
        isMegaModal: true,
        showCloseIcon: false,
      },
    );
  }, [modularObject?.id, modularObject?.templateId, showModal]);

  const handleAddClick = useCallback(async () => {
    const integrations: HardwareIntegration[] = selected?.map(objectId => ({
      objectId,
      objectType: 'modular_object',
      parentId: modularObject.id,
      parentType: 'modular_object',
      isPending: true,
    })) as HardwareIntegration[];

    scrollToId('nesting-section');
    onSubmit(integrations);
  }, [modularObject.id, onSubmit, scrollToId, selected]);

  const handleChange = useCallback((e) => {
    let _selected = [...selected];

    if (e.target.checked) {
      _selected.push(e.target.value);
    } else {
      _selected = selected.filter(s => s !== e.target.value);
    }

    setSelected(_selected);
  }, [selected]);

  const AddObjectButton = useMemo(() => {
    return (
      <Button
        className={cx('grow h-[unset] w-[unset] px-4 py-3 btn-ghost')}
        disabled={disableCreateObject}
        onClick={handleNewClick}
      >
        NEW BUILD
      </Button>
    );
  }, [disableCreateObject, handleNewClick]);

  const handleCheckAll = useCallback((e) => {
    if (e.target.checked) {
      setSelected(filteredResultList.map(obj => obj.id));
    } else {
      setSelected([]);
    }
  }, [filteredResultList]);

  if (isLoadingResults) {
    return (
      <MegaModal className='flex justify-center items-center w-screen gap-[24px] h-[80vh]'>
        <LoadingSpinner isLoading={true} />
      </MegaModal>
    );
  }

  return (
    <MegaModal className='w-screen gap-[24px] h-[80vh]'>
      <MegaModalHeader title='Select Builds to Add' />
      <div className='flex flex-col min-h-0 gap-[24px] grow'>
        <MegaModalSearchInput
          ref={inputRef}
          value={searchTerm}
          onChange={e => {
            setSearchTerm(e.target.value);
          }}
          placeholder='Search'
        />
        <div className='flex flex-col gap-2 min-h-0 grow'>
          <div
            className={cx('flex flex-row', {
              'pr-5': hasScrollbar,
            })}
          >
            {!filteredResultList.length
              ? <div className='text-xs tracking-widest text-gray-400 uppercase'>Results</div>
              : (
                <>
                  <div className='flex flex-row items-center min-w-0 flex-[2] gap-[8px]'>
                    <div className='flex flex-[2] gap-[8px]'>
                      <HeaderName
                        className='flex shrink'
                        text={
                          <input
                            type='checkbox'
                            onChange={handleCheckAll}
                            className='input-checkbox custom-checkbox checked:bg-primary'
                          />
                        }
                      />
                      <div className='flex-1 font-bold leading-[16px]'>Name</div>
                      {/* this div is a placeholder for the icon and allows the table to lign up properly */}
                      <div className='w-4' />
                    </div>
                    <div className='flex-1 font-bold leading-[16px]'>Build Type</div>
                    <div className='font-bold flex-[2] leading-[16px]'>Parent</div>
                  </div>
                  <div className='flex flex-1 min-w-0 gap-[8px]'>
                    <div className='flex-1 font-bold leading-[16px]'>Permission</div>
                    <div className='font-bold flex-[2] leading-[16px]'>Owner</div>
                    <div className='flex-1 font-bold leading-[16px]'>Company</div>
                  </div>
                </>
              )}
          </div>
          {!filteredResultList.length
            ? (
              <MegaModalEmptyList
                message='No builds match your search.'
                buttonDisabled={disableCreateObject}
                buttonText='Create this Build'
                buttonToolTip={disableCreateObject ? disableCreateObjectMessage : null}
                onClick={handleNewClick}
              />
            )
            : (
              <div className='flex overflow-y-auto flex-col flex-1' ref={tableRef} data-testid='add-new-object-list'>
                <div className='flex flex-col gap-[16px]'>
                  {filteredResultList.map((result) => {
                    const isSelected = selected.includes(result.id);
                    return (
                      <ObjectListRow
                        key={`add-new-object-list-row-${result.id}`}
                        object={result}
                        onChange={handleChange}
                        isSelected={isSelected}
                      />
                    );
                  })}
                </div>
              </div>
            )}
        </div>
      </div>
      <div className='flex flex-row gap-[32px]'>
        {disableCreateObjectMessage
          ? <InfoTooltip message={disableCreateObjectMessage} className='flex grow'>{AddObjectButton}</InfoTooltip>
          : AddObjectButton}

        <Button
          className={cx('grow h-[unset] w-[unset] px-4 py-3 btn-primary', {
            'cursor-not-allowed': !selected.length,
          })}
          onClick={handleAddClick}
          disabled={!selected.length}
        >
          ADD BUILD(S)
        </Button>
      </div>
    </MegaModal>
  );
}
