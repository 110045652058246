import { useEditContext } from '@/components/cards';
import { GetHistoryUpdatesForObjectIdDocument } from '@/components/cards/UpdatesColumn/getHistoryUpdatesForObjectId.generated';
import { GetModularObjectHistoryByIdDocument } from '@/components/cards/UpdatesColumn/getModularObjectDiffs.generated';
import { usePrismaticContext } from '@/components/Contexts/prismatic.context';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import {
  GetTemplatesUsedByModularObjectsDocument,
} from '@/graphql/sideNavigation/getTemplatesUsedByModularObjects.generated';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { buildFieldsPendingApprovalMap } from '@/util/approvals';
import cx from 'classnames';
import { debounce } from 'lodash';
import { type ChangeEvent, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { buildUpdateObjectDiffPayload } from '../../../utils';
import { useUpdateNameMutation } from './UpdateName.generated';

interface NameInputProps {
  isCompressedHeader?: boolean;
  placeholder?: string;
}

const NAME_FIELD_NAME = 'name';

export default function NameInput ({ isCompressedHeader, placeholder }: Readonly<NameInputProps>): JSX.Element {
  const { canUserEdit } = useEditContext();
  const { getValues } = useFormContext();
  const { objectCardData } = useObjectCardContext();
  const { canDeleteJiraTask, updateJiraTicket } = usePrismaticContext();
  const [objectName, setObjectName] = useState(objectCardData?.name);
  const [lastSavedName, setLastSavedName] = useState(objectCardData?.name);
  const value = getValues(NAME_FIELD_NAME);
  const [updateNameMutation] = useUpdateNameMutation();

  const updateName = useCallback(async (newFieldValue: string) => {
    const updateNamePayload = buildUpdateObjectDiffPayload({
      objectId: objectCardData?.id,
      fieldName: NAME_FIELD_NAME,
      newFieldValue,
    });

    await updateNameMutation({
      variables: {
        input: {
          diffs: [updateNamePayload],
        },
      },
      refetchQueries: [
        GetModularObjectHistoryByIdDocument,
        GetHistoryUpdatesForObjectIdDocument,
        GetTemplatesUsedByModularObjectsDocument,
      ],
      onCompleted: (data) => {
        const fieldsPendingApprovalMap = buildFieldsPendingApprovalMap(data.updateModularObject?.[0]?.approvals);
        const isSaved = !fieldsPendingApprovalMap[NAME_FIELD_NAME];

        const SUCCESS_MESSAGE = isSaved
          ? 'Successfully saved name'
          : 'Successfully requested approval for name';

        addToastSuccess(SUCCESS_MESSAGE);
      },
      onError: () => addToastError('Failed to update name, please try again'),
    });
    setLastSavedName(newFieldValue);

    if (canDeleteJiraTask) {
      await updateJiraTicket();
    }
  }, [canDeleteJiraTask, objectCardData?.id, updateJiraTicket, updateNameMutation]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateName = useCallback(
    debounce(async objectName => {
      await updateName(objectName);
    }, 2000),
    [updateName],
  );

  const handleBlur = useCallback(async () => {
    if (objectCardData?.name === objectName || objectName === lastSavedName) return;
    debouncedUpdateName.cancel();
    await updateName(objectName);
  }, [debouncedUpdateName, lastSavedName, objectCardData?.name, objectName, updateName]);

  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLTextAreaElement>) => {
      const newFieldValue = e.target.value;

      setObjectName(newFieldValue);
      if (objectCardData?.name === newFieldValue || objectName === lastSavedName) return;
      debouncedUpdateName.cancel();

      await debouncedUpdateName(newFieldValue);
    },
    [debouncedUpdateName, lastSavedName, objectCardData?.name, objectName],
  );

  if (!canUserEdit) {
    return (
      <div
        data-testid='object-card-cant-edit-name-input transform-gpu'
        className={cx('py-2 font-effra flex items-center', {
          'px-0 text-nowrap h-[38px] duration-500 text-[16px] overflow-x-scroll no-scrollbar font-medium':
            isCompressedHeader,
          'px-3 text-wrap h-full font-bold text-[24px] ': !isCompressedHeader,
        })}
      >
        {value}
      </div>
    );
  }

  return (
    <textarea
      data-testid='hook-form-text-area-wrapper'
      value={objectName}
      name={NAME_FIELD_NAME}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      className={cx(
        ' py-2 placeholder-neutral-200 no-scrollbar duration-300  font-effra focus:border-b-primary hover:border-b-black hover:border-neutral-200 focus:border-neutral-200 resize-none focus:border-b input transition-all transform-gpu grow',
        {
          'px-3 text-nowrap text-[16px] h-[38px] font-medium': isCompressedHeader,
          'px-3 text-wrap text-[24px] h-[88px] font-bold': !isCompressedHeader,
        },
      )}
      autoComplete='off'
      id={NAME_FIELD_NAME}
      defaultValue={value || ''}
    />
  );
}
