import { addApprovals } from '@/state/slices/approvals.slice';
import { addDiffs } from '@/state/slices/diffs.slice';
import {
  addNotifications,
  setIsSubscriptionActive,
  setOrganizationAdmin,
  setUser,
  setUserPreferences,
} from '@/state/slices/session.slice';
import { addShares } from '@/state/slices/shares.slice';
import { addUsers } from '@/state/slices/user.slice';
import { API_URL } from '@/util/constants';
import { env } from '@/util/env.utils';
import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { getCookie } from 'cookies-next';
import { ClientError, gql, GraphQLClient } from 'graphql-request';

const graphqlBaseQuery = ({ baseUrl }: { baseUrl: string }) =>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
async ({ body, token, variables }: { body: string; token: string; variables?: any }) => {
  try {
    const impersonationEmail = localStorage.getItem('impersonation');
    const graphQLClient = new GraphQLClient(`${API_URL()}${baseUrl}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        ...(impersonationEmail ? { 'x-impersonate': impersonationEmail } : {}),
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
      credentials: 'include',
    });
    const result = await graphQLClient.request(body, variables);
    return { data: result };
  } catch (error) {
    if (error instanceof ClientError) {
      return { error: { status: error.response.status, data: error.response } };
    }
    return { error: { status: 500, data: error.response } };
  }
};

// TODO: replace the other queries with .graphql files
export const graphqlApi2 = createApi({
  reducerPath: 'api',
  baseQuery: graphqlRequestBaseQuery({
    url: `${API_URL()}/graphql`,

    prepareHeaders: (headers) => {
      const token = getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'));
      const impersonationEmail = localStorage.getItem('impersonation');

      if (impersonationEmail) {
        headers.set('x-impersonate', impersonationEmail);
      }
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
});

export const graphqlApi = createApi({
  reducerPath: 'graphqlApi',
  baseQuery: graphqlBaseQuery({
    baseUrl: '/graphql',
  }),
  endpoints: (builder) => ({
    fetchUsersById: builder.query({
      query: (options) => ({
        token: (options?.token || '') as string,
        body: gql`
          query ($ids: [UUID!]!) {
            getUsersByID(ids: $ids) {
              ${userFields}
            }
          }
        `,
        variables: options?.variables,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        let data;
        let errors;
        try {
          data = await queryFulfilled;
          data = data?.data;
        } catch (err) {
          if (err.error.status === 402) {
            const adminUser = err.error?.data?.data?.admin;
            dispatch(setIsSubscriptionActive(false));
            if (adminUser) {
              dispatch(setOrganizationAdmin(adminUser));
            }
            return;
          }

          data = err.error.data?.data;
          errors = err.error.data?.errors;
          console.error(errors); // TODO: probably set this in state or something
        }

        if (data) {
          const {
            getUsersByID,
          } = data;

          dispatch(addUsers(getUsersByID));
        }
      },
    }),
    fetchUserData: builder.query({
      query: (options) => ({
        token: (options?.token || '') as string,
        body: gql`
          query {
            getUser {
              ${userFields}
            },
            getUserPreferences {
              ${userPreferencesFields}
            },
          }
        `,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        let data;
        let errors;
        try {
          data = await queryFulfilled;
          data = data?.data;
        } catch (err) {
          if (err.error.status === 402) {
            const adminUser = err.error?.data?.data?.admin;
            dispatch(setIsSubscriptionActive(false));
            if (adminUser) {
              dispatch(setOrganizationAdmin(adminUser));
            }
            return;
          }

          data = err.error.data?.data;
          errors = err.error.data?.errors;
          console.error(errors); // TODO: probably set this in state or something
        }

        if (data) {
          const {
            getUser,
            getUserPreferences,
          } = data;

          dispatch(setUser(getUser));
          dispatch(setUserPreferences(getUserPreferences));
        }
      },
    }),
    fetchObjectHistoryData: builder.query({
      query: (options) => ({
        token: (options?.token || '') as string,
        body: gql`
          query {
            getModularObjectHistory {
              ${historyFields}
            }
          }
        `,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        let data;
        let errors;
        try {
          data = await queryFulfilled;
          data = data?.data;
        } catch (err) {
          if (err.error.status === 402) {
            const adminUser = err.error?.data?.data?.admin;
            dispatch(setIsSubscriptionActive(false));
            if (adminUser) {
              dispatch(setOrganizationAdmin(adminUser));
            }
            return;
          }

          data = err.error.data?.data;
          errors = err.error.data?.errors;
          console.error(errors); // TODO: probably set this in state or something
        }

        if (data) {
          const {
            getModularObjectHistory,
          } = data;

          dispatch(addDiffs([
            ...getModularObjectHistory,
          ].filter(Boolean)));
        }
      },
    }),
    fetchSharesData: builder.query({
      query: (options) => ({
        token: (options?.token || '') as string,
        body: gql`
          query {
            modularObjectShares: getSharesByType(objType: "modular_object") {
              ${shareFields}
            }
          }
        `,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        let data;
        let errors;
        try {
          data = await queryFulfilled;
          data = data?.data;
        } catch (err) {
          if (err.error.status === 402) {
            const adminUser = err.error?.data?.data?.admin;
            dispatch(setIsSubscriptionActive(false));
            if (adminUser) {
              dispatch(setOrganizationAdmin(adminUser));
            }
            return;
          }

          data = err.error.data?.data;
          errors = err.error.data?.errors;
          console.error(errors); // TODO: probably set this in state or something
        }

        if (data) {
          const {
            modularObjectShares,
          } = data;

          dispatch(addShares([
            ...modularObjectShares,
          ].filter(Boolean)));
        }
      },
    }),
    fetchApprovalData: builder.query({
      query: (options) => ({
        token: (options?.token || '') as string,
        body: gql`
          query {
            getOrganizationJoinRequests {
              ${organizationJoinRequestFields}
            }
          }
        `,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        let data;
        let errors;
        try {
          data = await queryFulfilled;
          data = data?.data;
        } catch (err) {
          if (err.error.status === 402) {
            const adminUser = err.error?.data?.data?.admin;
            dispatch(setIsSubscriptionActive(false));
            if (adminUser) {
              dispatch(setOrganizationAdmin(adminUser));
            }
            return;
          }

          data = err.error.data?.data;
          errors = err.error.data?.errors;
          console.error(errors); // TODO: probably set this in state or something
        }

        if (data) {
          const {
            getOrganizationJoinRequests,
          } = data;
          dispatch(addApprovals({ type: 'organizationJoinRequests', approvals: getOrganizationJoinRequests }));
        }
      },
    }),
    fetchNotificationData: builder.query({
      query: (options) => ({
        token: (options?.token || '') as string,
        body: gql`
          query {
            getNotifications {
              ${notificationFields}
            }
          }
        `,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        let data;
        let errors;
        try {
          data = await queryFulfilled;
          data = data?.data;
        } catch (err) {
          if (err.error.status === 402) {
            const adminUser = err.error?.data?.data?.admin;
            dispatch(setIsSubscriptionActive(false));
            if (adminUser) {
              dispatch(setOrganizationAdmin(adminUser));
            }
            return;
          }

          data = err.error.data?.data;
          errors = err.error.data?.errors;
          console.error(errors); // TODO: probably set this in state or something
        }

        if (data) {
          const { getNotifications } = data;

          dispatch(addNotifications(getNotifications));
        }
      },
    }),
  }),
});

const organizationFields = `
  id,
  name,
  logo,
  logoHorizontal,
  maxUsers,
  organizationTypes,
  invitationsRemaining,
  hardware
  createdAt
  modifiedAt
  createdById
  createdByUser {
    id
    email
    address
    title
    firstName
    lastName
    organizationId
    profileImage
    role
  }
`;

const organizationJoinRequestFields = `
  id,
  organizationId,
  email,
  firstName,
  lastName,
  password,
  createdAt,
  deletedAt,
`;

const userFields = `
  id,
  email,
  address,
  title,
  firstName,
  lastName,
  organizationId,
  organization {
    ${organizationFields}
  },
  phoneNumber,
  profileImage,
  role,
  acknowledgements {
    termsOfUse
  },
  lastLoggedIn
`;

const userPreferencesFields = `
  id,
  userId,
  preferences
`;

const notificationFields = `
  id
  userId
  externalType
  notificationType
  createdAt
  modifiedAt
`;

const shareFields = `
  id
  externalType
  externalId
  role
  userId
  createdAt
  modifiedAt
`;

const historyFields = `
  id
  externalID
  externalType
  diffType
  diff {
      from
      to
      displayNames
  }
  createdAt
  createdBy
  fromMigration
`;
