import type { User } from '@/__generated__/types';
import { ProfileImage } from '@/components/ProfileImage/ProfileImage';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import cx from 'classnames';

interface CollaboratorProps {
  user: User;
  withTitle?: boolean;
}

export default function Collaborator ({ user, withTitle = false }: CollaboratorProps) {
  const loggedInUser = useLoggedInUser();

  const isInternal = user?.organization?.id === loggedInUser?.organizationId ||
    user?.organizationId === loggedInUser?.organizationId;

  const isDeleted = Boolean(user?.deletedAt);

  return (
    <span
      className={cx('flex gap-2 items-center', {
        'opacity-50': isDeleted,
      })}
    >
      <ProfileImage user={user} size='collaborator' />
      <div className='flex flex-col'>
        <p
          className={cx('effra-14', {
            'text-gray-60': isDeleted,
            'text-primary': isInternal && !isDeleted,
            'text-tertiary': !isInternal && !isDeleted,
          })}
        >
          {user?.fullName?.trim()}
          {isDeleted && <span className='effra-12 text-gray-60'>(Removed User)</span>}
        </p>
        <p>{withTitle && user?.title}</p>
      </div>
    </span>
  );
}
