import { useEditContext } from '@/components';
import DisplayValue from '@/components/modules/DisplayValue';
import type HookFormLabel from '@/components/modules/HookFormLabel';
import { faArrowRight, faCalendarCheck, faCalendarPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as chrono from 'chrono-node';
import cx from 'classnames';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { addToastError } from '@/components/Toast/utils';
import { faChevronLeft, faChevronRight } from '@fortawesome/sharp-regular-svg-icons';
import dayjs, { type ManipulateType } from 'dayjs';
import ScrollParent from 'scrollparent';
import { v4 as uuid } from 'uuid';
import getParentRangeColor from './utils/getParentRangeColor';

export enum ParentRangeColor {
  Default = 'default',
  Internal = 'internal',
  External = 'external',
}

export interface DatePickerInputProps {
  id?: string;
  className?: string;
  dateFormat?: string;
  showArrowAfter?: boolean;
  labelProps?: Partial<React.ComponentProps<typeof HookFormLabel>>;
  startDate?: Date;
  endDate?: Date;
  parentStartDate?: Date;
  parentEndDate?: Date;
  parentRangeColor?: ParentRangeColor;
  onChange?: (date: string) => void;
  value?: Date;
  error?: string;
  name?: string;
  placeholder?: string;
  inputClassName?: string;
  isOpen?: boolean;
  view?: 'month' | 'year' | 'decade' | 'century';
}

export default forwardRef(function DatePickerInput ({
  id = uuid(),
  className,
  dateFormat = 'MMM D, YYYY',
  showArrowAfter,
  labelProps = {},
  startDate,
  endDate,
  parentStartDate,
  parentEndDate,
  parentRangeColor,
  onChange,
  value,
  error,
  name,
  placeholder,
  inputClassName,
  isOpen = false,
  view = 'month',
}: DatePickerInputProps, ref: React.Ref<HTMLInputElement>): JSX.Element {
  const inputHeight = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement>();
  const calendarRef = useRef<HTMLDivElement>();
  const calendarHeightRef = useRef<number>(0);
  const inputRef = useRef<HTMLInputElement>();
  const { canUserEdit } = useEditContext();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(isOpen);
  const [xSide, setXSide] = useState<'left' | 'right'>('left');
  const [yPosition, setYPosition] = useState(0);
  const [inputValue, setInputValue] = useState(value ? dayjs(value).format(dateFormat) : '');
  const [currentView, setCurrentView] = useState<'month' | 'year' | 'decade' | 'century'>(view);
  const [activeStartDate, setActiveStartDate] = useState<Date>(
    value ? new Date(value) : startDate ? new Date(startDate) : endDate ? new Date(endDate) : new Date(),
  );
  const parsedId = id.replace(/\./g, '_'); // Module id's contain periods which are not valid in css selectors which I need for the click "out" handling

  useEffect(() => {
    // SORRY FOR THIS HACK
    setInputValue(value ? dayjs(value).format(dateFormat) : '');
  }, [dateFormat, value]);

  useEffect(() => {
    // This useEffect is used to update the activeStartDate when the startDate or endDate props change, as useState doesn't re-run on prop changes
    try {
      setActiveStartDate(
        value ? new Date(value) : startDate ? new Date(startDate) : endDate ? new Date(endDate) : new Date(),
      );
    } catch (e) {
      console.error('Error setting activeStartDate', e);
    }
  }, [value, startDate, endDate]);

  const setCalendarPosition = useCallback((newInputHeight?: number) => {
    if (!inputRef.current) return;

    const { top: inputTop, bottom: inputBottom, left: inputLeft, right: inputRight } = inputRef.current
      .getBoundingClientRect();
    const scrollParent = ScrollParent(inputRef.current);

    const { top: scrollParentTop, bottom: scrollParentBottom, left: scrollParentLeft, right: scrollParentRight } =
      scrollParent.getBoundingClientRect();

    const distanceToTopOfScrollParent = inputTop -
      scrollParentTop;
    const distanceToBottomOfScrollParent = scrollParentBottom -
      inputBottom;
    const distanceToRightSideOfScrollParent = scrollParentRight -
      inputRight;
    const distanceToLeftSideOfScrollParent = inputLeft -
      scrollParentLeft;

    // If there is more space above the input than below it, open the calendar above the input
    if (distanceToTopOfScrollParent > distanceToBottomOfScrollParent) {
      setYPosition(-calendarHeightRef.current);
    } else {
      // If there is more space below the input than above it, open the calendar below the input
      setYPosition(newInputHeight || inputHeight.current);
    }

    // If there is more space to the right of the input than to the left of it, open the calendar to the left of the input
    if (distanceToRightSideOfScrollParent > distanceToLeftSideOfScrollParent) {
      setXSide('left');
    } else {
      setXSide('right');
    }
  }, [setYPosition, calendarHeightRef, inputHeight]);

  useEffect(() => {
    const scrollParent = ScrollParent(inputRef.current);

    if (scrollParent) {
      const scrollEventListener = () => {
        setCalendarPosition();
      };

      scrollParent.addEventListener('scroll', scrollEventListener);

      return () => {
        scrollParent.removeEventListener('scroll', scrollEventListener);
      };
    }
  }, [setCalendarPosition]);

  const getInputHeight = useCallback(() => {
    calendarHeightRef.current = calendarRef?.current?.getBoundingClientRect().height;
    const newInputHeight = containerRef?.current?.getBoundingClientRect().height;
    inputHeight.current = newInputHeight;
    return newInputHeight;
  }, [calendarHeightRef, calendarRef, containerRef, inputHeight]);

  useEffect(() => {
    const newInputHeight = getInputHeight();
    if (inputRef.current) {
      setCalendarPosition(newInputHeight);
    }
  }, [setCalendarPosition, getInputHeight]);

  const closeDatePicker = useCallback(() => {
    setActiveStartDate(
      value ? new Date(value) : startDate ? new Date(startDate) : endDate ? new Date(endDate) : new Date(),
    );
    setCurrentView('month');
    setIsDatePickerOpen(false);
  }, [endDate, startDate, value]);

  useEffect(() => {
    // Handle closing the datepicker when clicking outside of it
    const clickEventListener = (e: MouseEvent) => {
      if (!isDatePickerOpen) return;

      const el = e.target as HTMLElement;

      // All the possible parent elements that can indicate the click happened inside the datepicker
      const datepickerEl = el.closest(`.datepicker-container.${parsedId}`);
      const datePickerYearViewEl = el.closest('.react-calendar__year-view');
      const datePickerYearDecadeEl = el.closest('.react-calendar__decade-view');
      const inputEl = el.closest(`.react-calendar-input.${parsedId}`);

      if (!datepickerEl && !datePickerYearViewEl && !datePickerYearDecadeEl && !inputEl) {
        closeDatePicker();
      }
    };
    window.addEventListener('click', clickEventListener);

    // Handle closing the datepicker when pressing escape
    const keydownEventListener = (e) => {
      if (!isDatePickerOpen) return;

      if (e.key === 'Escape') {
        e.stopPropagation();
        closeDatePicker();
      }
    };

    const containerEl = containerRef.current;

    if (containerEl) {
      containerEl.addEventListener('keydown', keydownEventListener);
    }

    return () => {
      window.removeEventListener('click', clickEventListener);
      if (containerEl) {
        containerEl.removeEventListener('keydown', keydownEventListener);
      }
    };
  }, [isDatePickerOpen, parsedId, closeDatePicker]);

  const handleDateChange = useCallback((date) => {
    if (
      endDate &&
      dayjs(date).isAfter(endDate)
    ) {
      addToastError('Start date must be before end date');
      return;
    }
    if (
      startDate &&
      dayjs(date).isBefore(startDate)
    ) {
      addToastError('End date must be after start date');
      return;
    }

    setInputValue(dayjs(date).format(dateFormat));

    // Only call onChange if the value has changed
    if ((onChange && !value) || dayjs(value).startOf('day').diff(date, 'day')) {
      onChange(date);
    }
  }, [dateFormat, endDate, onChange, startDate, value]);

  const handleInputFocus = useCallback((e) => {
    const newInputHeight = getInputHeight();
    setCalendarPosition(newInputHeight);
    setIsDatePickerOpen(true);
  }, [setCalendarPosition, getInputHeight]);

  const handleValueSelected = useCallback(() => {
    const date = chrono.parseDate(inputValue);
    if (date) {
      handleDateChange(date);
    } else if (value && !date) {
      onChange(null);
    }

    if (isDatePickerOpen) {
      closeDatePicker();
    }
  }, [inputValue, handleDateChange, value, onChange, isDatePickerOpen, closeDatePicker]);

  const handleBlur = useCallback((e) => {
    if (e.relatedTarget && !e.relatedTarget.classList.contains('react-calendar-input')) {
      // This is a hack to prevent the datepicker from closing when clicking on a date in the calendar
      return;
    }

    handleValueSelected();
  }, [handleValueSelected]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      // This allows the user to tab out of the input if they haven't entered any text into the input
      // so they can continue tabbing into the calendar component
      if (e.key === 'Tab' && (inputValue === dayjs(value).format(dateFormat) || dayjs(inputValue).isSame(value))) {
        return;
      }

      handleValueSelected();
    }
  }, [handleValueSelected, inputValue, value, dateFormat]);

  const handleHeaderMonthClick = useCallback((e) => {
    e.preventDefault();
    setCurrentView('year');
  }, []);

  const handleHeaderYearClick = useCallback((e) => {
    e.preventDefault();
    setCurrentView('decade');
  }, []);

  const handleMonthClick = useCallback((date) => {
    setActiveStartDate(dayjs(date).toDate());
    setCurrentView('month');
  }, [setActiveStartDate]);

  const handleYearClick = useCallback((date) => {
    // Keep current month instead of resetting to January
    date = dayjs(date).month(dayjs(activeStartDate).month());
    setActiveStartDate(dayjs(date).toDate());
    setCurrentView('month');
  }, [activeStartDate, setActiveStartDate]);

  const handleNextPrevClick = useCallback((direction: 'next' | 'prev') => {
    let updateAmount = 1;
    let updateType: ManipulateType = 'month';

    if (currentView === 'year') {
      updateType = 'year';
    } else if (currentView === 'decade') {
      updateType = 'year';
      updateAmount = 10;
    }

    if (direction === 'next') {
      setActiveStartDate(dayjs(activeStartDate).add(updateAmount, updateType).toDate());
    } else {
      setActiveStartDate(dayjs(activeStartDate).subtract(updateAmount, updateType).toDate());
    }
  }, [activeStartDate, currentView, setActiveStartDate]);

  const placeholderName = (labelProps?.label || '').replace(':', '').toLowerCase();
  const placeholderText = placeholder || (placeholderName ? `Enter ${placeholderName}` : 'Enter value');

  const today = dayjs();

  const parentRangeClasses = getParentRangeColor(parentRangeColor);

  return canUserEdit ?
    (
      <div
        className={cx('flex flex-col relative datepicker-container', className, parsedId)}
        ref={containerRef}
        data-testid='date-picker-input'
      >
        <div className='flex relative grow'>
          <div className='w-full grow'>
            <FontAwesomeIcon
              icon={value ? faCalendarCheck : faCalendarPlus}
              className={cx('absolute translate-x-2.5 translate-y-3', {
                'text-neutral-400': !value,
              })}
            />
            <div className='flex flex-col gap-1' ref={inputRef}>
              <input
                onChange={e => {
                  setInputValue(e.target.value);
                }}
                onFocus={handleInputFocus}
                onBlur={handleBlur}
                id={name}
                className={cx(
                  'input-text min-w-[80px] pl-9',
                  'react-calendar-input',
                  inputClassName,
                  parsedId,
                )}
                onKeyDown={handleKeyDown}
                name={name}
                value={inputValue}
                placeholder={placeholderText}
                type='text'
                ref={ref}
              />
              {Boolean(error) && (
                <span className='text-red-500'>
                  Please enter a valid date
                </span>
              )}
            </div>
          </div>
          {showArrowAfter &&
            (
              <div className='flex items-center mx-5'>
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            )}
        </div>
        <div
          ref={calendarRef}
          tabIndex={0} // Added this to fix a onBlur issue in safari where every click inside the calendar would trigger a blur event on the input and close the datepicker
          className={cx(
            'absolute',
            'bg-white',
            'border-[1px]',
            'border-[#E6E6E6]',
            'shadow-[0px_7px_20px_-2px_rgba(0,0,0,0.12)]',
          )}
          style={{
            top: yPosition,
            [xSide]: 0,
            visibility: isDatePickerOpen ? 'visible' : 'hidden', // Using this as a style because jest doesn't seem to like the `invisible` class from tailwindcss
            zIndex: isDatePickerOpen ? 50 : -50,
          }}
          data-testid={`date-picker-calendar-${name}`}
        >
          <div className='flex gap-[4px] p-[12px] pb-[18px]'>
            <button
              onClick={e => {
                e.preventDefault();
                handleNextPrevClick('prev');
              }}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                className='text-[#999]'
              />
            </button>
            <div>
              <div className='flex justify-center items-center gap-[8px] leading-[16px] p-[8px] pt-[4px]'>
                <button
                  onClick={handleHeaderMonthClick}
                  className={cx('border-[1px] py-[4px] px-[18px] rounded-[8px]', {
                    'text-[#999]': currentView !== 'year',
                    'border-[#E6E6E6]': currentView !== 'year',
                    'border-[#666]': currentView === 'year',
                    'text-[#666]': currentView === 'year',
                  })}
                >
                  {dayjs(activeStartDate).format('MMMM')}
                </button>
                <button
                  onClick={handleHeaderYearClick}
                  className={cx('border-[1px] py-[4px] px-[18px] rounded-[8px]', {
                    'text-[#999]': currentView !== 'decade',
                    'border-[#E6E6E6]': currentView !== 'decade',
                    'border-[#666]': currentView === 'decade',
                    'text-[#666]': currentView === 'decade',
                  })}
                >
                  {dayjs(activeStartDate).format('YYYY')}
                </button>
              </div>
              <Calendar
                className={cx(id, '!border-0')}
                activeStartDate={activeStartDate}
                value={value}
                view={currentView}
                onChange={handleDateChange}
                onClickDay={_ => {
                  closeDatePicker();
                }}
                onClickMonth={handleMonthClick}
                onClickYear={handleYearClick}
                showFixedNumberOfWeeks={true}
                showNavigation={false}
                minDate={startDate}
                maxDate={endDate}
                tileClassName={({ date, view }) => {
                  switch (view) {
                    case 'month':
                      return cx('h-[28px] !py-[2px] !px-[6px] relative', {
                        'today': dayjs(date).isSame(today, 'day'),
                        [parentRangeClasses.startAndEnd]: dayjs(date).isSame(parentStartDate, 'day'), // Has start and end date
                        [parentRangeClasses.startOnly]: dayjs(date).isSame(parentStartDate, 'day') && !parentEndDate, // Only has start date
                        [parentRangeClasses.endAndStart]: dayjs(date).isSame(parentEndDate, 'day'), // Has start and end date
                        [parentRangeClasses.endOnly]: dayjs(date).isSame(parentEndDate, 'day') && !parentStartDate, // Only has end date
                        [parentRangeClasses.within]: dayjs(date).isAfter(parentStartDate, 'day') &&
                          dayjs(date).isBefore(parentEndDate, 'day'),
                      });
                    case 'year':
                      return '';
                    default:
                      break;
                  }
                }}
                tileContent={({ date, view }) => {
                  if (view === 'month') {
                    if (dayjs(date).isSame(parentStartDate, 'day')) {
                      return <div title='Parent start date' className='absolute top-0 right-0 bottom-0 left-0' />;
                    }

                    if (dayjs(date).isAfter(parentStartDate, 'day') && dayjs(date).isBefore(parentEndDate, 'day')) {
                      return (
                        <div title='Within parent date range' className='absolute top-0 right-0 bottom-0 left-0' />
                      );
                    }

                    if (dayjs(date).isSame(parentEndDate, 'day')) {
                      return <div title='Parent end date' className='absolute top-0 right-0 bottom-0 left-0' />;
                    }
                  }

                  return null;
                }}
                formatMonth={(_, date) =>
                  dayjs(date).format('MMM')}
              />
            </div>
            <button
              onClick={e => {
                e.preventDefault();
                handleNextPrevClick('next');
              }}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                className='text-[#999]'
              />
            </button>
          </div>
        </div>
      </div>
    ) :
    (
      <DisplayValue
        value={value ? dayjs(value).format(dateFormat) : ''}
        className={inputClassName}
        labelProps={labelProps}
        dateFormat={dateFormat}
      />
    );
});
