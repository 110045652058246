import FormContainer from '@/components/FormContainer/FormContainer';
import { useCallback, useState } from 'react';
import DragArea from './DragArea';
import Errors from './Errors';

import type { TaskMap } from '@/components/flows/UploadCsvUserFlow/steps/MapHeader/constants';
import type { StepContentProps } from '@/components/Stepper/Stepper';
import type { ParsedFileData } from './types';

interface DragDropFileModalProps extends StepContentProps {
  open: boolean;
  onClose: () => void;
  onDropCallback?: (data: { header: string[]; rows: TaskMap[] }) => void;
  onStepChange?: (step: number) => void;
  step: number;
}

export default function DragDropFileForm ({
  onClose,
  onStepChange,
  step,
  incrementStep = () => {},
  onDropCallback = () => {},
}: DragDropFileModalProps): JSX.Element {
  const [error, setError] = useState('');
  const onDropCallbackWrapper = useCallback((data: ParsedFileData) => {
    const cleanedRows = data?.rows.map((row) => {
      return Object.fromEntries(
        Object.entries(row).filter(([key]) => Boolean(key) && key !== 'undefined'),
      ) as TaskMap;
    });
    onStepChange(step + 1);
    incrementStep();
    onDropCallback({ header: data?.header ?? [], rows: cleanedRows ?? [] });
  }, [incrementStep, onDropCallback, onStepChange, step]);

  return (
    <FormContainer
      headerText='Upload Driver Template'
      content={<DragArea onDropCallback={onDropCallbackWrapper} setError={setError} />}
      onClose={onClose}
      errors={<Errors error={error} />}
    />
  );
}
