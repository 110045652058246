import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
export type TaskFragment = { __typename?: 'ModularObject', id: string, name: string | null, startDate: any | null, targetDate: any | null, templateId: string | null, assigneeId: string | null, ownerId: string | null, visibility: string | null, deactivated: boolean | null, status: Types.TaskStatus | null, statusModuleId: string | null, permission: Types.Permission | null, owner: { __typename?: 'User', id: string, organizationId: string | null } | null, assignee: { __typename?: 'User', id: string, organizationId: string | null } | null, parent: { __typename?: 'ModularObject', id: string, name: string | null } | null, children: Array<{ __typename?: 'ModularObject', id: string, name: string | null }> | null, template: { __typename?: 'Template', id: string | null, name: string | null, type: Types.TemplateTypes | null, subType: Types.TemplateSubTypes | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, role: string | null, userId: string | null }> | null };

export const TaskFragmentDoc = gql`
    fragment Task on ModularObject {
  id
  name
  startDate
  targetDate
  templateId
  assigneeId
  ownerId
  visibility
  deactivated
  status
  statusModuleId
  owner(includeDeleted: true) {
    id
    organizationId
  }
  assignee(includeDeleted: true) {
    id
    organizationId
  }
  parent {
    id
    name
  }
  children {
    id
    name
  }
  template {
    id
    name
    type
    subType
  }
  permission
  shares {
    id
    role
    userId
  }
}
    `;