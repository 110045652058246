import InfoTooltip from '@/components/tooltip/InfoTooltip';
import { faJira } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface JiraIntegrationStatusLabelProps {
  enabled: boolean;
  ticket: string;
  url: string;
}

export function JiraIntegrationStatusLabel (
  { enabled, ticket, url }: Readonly<JiraIntegrationStatusLabelProps>,
): JSX.Element {
  return (
    <div className='flex relative justify-end pr-14'>
      <InfoTooltip
        className='flex absolute top-0 items-center mt-1'
        message={`This driver is being populated\n by data from a linked jira ticket.\n Ticket: ${ticket}.`}
        delay={0.25}
      >
        <span
          className='flex gap-2 items-start cursor-pointer text-gray-40 effra-12'
          onClick={() => {
            window.open(url, '_blank', 'noreferrer');
          }}
        >
          <FontAwesomeIcon
            className='self-center'
            icon={faJira}
          />
          <span>
            Jira sync {enabled ? 'on' : 'off'}
          </span>
        </span>
      </InfoTooltip>
    </div>
  );
}
