import type { TaskFragment } from '@/components/Schedule/queries/taskFragment.generated';
import type { ScheduleModularObjectFragment } from '@/graphql/fragments/modularObject.generated';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import cx from 'classnames';

interface TaskNameProps {
  object: TaskFragment | ScheduleModularObjectFragment;
  depth?: number;
  className?: string;
}

export default function TaskName ({ object, depth, className }: TaskNameProps): JSX.Element {
  const currentUser = useLoggedInUser();
  const hasAssignee = Boolean(object?.assigneeId);
  const isExternalOwner = currentUser?.organizationId !== object?.owner?.organizationId;
  const isExternalAssignee = currentUser?.organizationId !== object?.assignee?.organizationId && hasAssignee;
  const isExternal = isExternalOwner || isExternalAssignee;

  return (
    <div
      className={cx('flex items-center effra-14 text-left', className, {
        'text-black': !isExternal,
        'text-tertiary': isExternal,
      })}
    >
      <div className={cx('truncate whitespace-nowrap', className)}>
        {object?.name}
      </div>
    </div>
  );
}
