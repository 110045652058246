import CollaboratorList from './CollaboratorList';
import CollaboratorListContainer from './CollaboratorListContainer';
import useCollaboratorRemovalQueue from './hooks/useCollaboratorRemovalQueue';
import ModalHeader from './ModalHeader';
import RemoveButton from './RemoveButton';

interface RemoveCollaboratorsModalProps {
  selectedObjectIds: string[];
  closeModal: () => void;
}

export default function RemoveCollaboratorsModal ({ selectedObjectIds, closeModal }: RemoveCollaboratorsModalProps) {
  const {
    selected,
    collaborators,
    isLoading,
    addCollaborator,
  } = useCollaboratorRemovalQueue(selectedObjectIds);

  return (
    <div className='flex flex-col gap-4 px-[24px] pt-[16px] pb-[36px] w-[448px]'>
      <ModalHeader closeModal={closeModal} />
      <CollaboratorListContainer
        collaborators={collaborators}
        isLoading={isLoading}
      >
        <CollaboratorList
          collaborators={collaborators}
          selected={selected}
          addCollaborator={addCollaborator}
        />
      </CollaboratorListContainer>
      <RemoveButton
        selected={selected}
        closeModal={closeModal}
        disabled={selected.length === 0}
      />
    </div>
  );
}
