import cx from 'classnames';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import FormContainer from '../../../../FormContainer/FormContainer';
import { DISPLAY_TO_TASK_FIELD, REQUIRED_FIELDS, type TaskMap } from './constants';
import MapHeaderForm from './MapHeaderForm';

import type { StepContentProps } from '@/components/Stepper/Stepper';

interface MapHeaderProps extends StepContentProps {
  header: string[];
  onClose: () => void;
  onStepChange: (step: number) => void;
  step: number;
  numTasks: number;
  onMapCallback: (map: TaskMap) => void;
}

export default function MapHeader ({
  header,
  onClose,
  onStepChange,
  step,
  incrementStep,
  onMapCallback,
  numTasks,
}: MapHeaderProps): JSX.Element {
  const [taskMap, setTaskMap] = useState<TaskMap>({});
  const [unMappedRequiredFields, setUnMappedRequiredFields] = useState<number>(Infinity);

  useEffect(() => {
    const newMap = header.reduce((acc, headerName) => {
      const headerMatch = DISPLAY_TO_TASK_FIELD[headerName?.toLocaleLowerCase()];
      if (headerMatch) {
        acc[headerName] = headerMatch;
      }
      return acc;
    }, {});
    setTaskMap(newMap);
  }, [header]);

  useEffect(() => {
    const taskValues = Object.values(taskMap);
    setUnMappedRequiredFields(
      REQUIRED_FIELDS.filter(
        field => !taskValues.includes(field),
      ).length,
    );
  }, [taskMap]);

  const taskVerbiage = numTasks === 1 ? 'driver' : 'drivers';
  const buttonText = unMappedRequiredFields
    ? `*Match Required Fields to Proceed (${unMappedRequiredFields})`
    : 'Continue with ' + `${numTasks} ${taskVerbiage}`;

  return (
    <FormContainer
      headerText='Map your column headers'
      styleOptions='overflow-y-auto max-h-[80vh]'
      content={<MapHeaderForm header={header} setTaskMap={setTaskMap} taskMap={taskMap} />}
      onClose={onClose}
      stickyButtons
      buttons={
        <div className='flex flex-col gap-4 w-full'>
          <button
            type='button'
            className={cx('effra-xs font-medium text-xs w-full', {
              'btn-primary': !unMappedRequiredFields,
              'btn-warning-ghost cursor-not-allowed': unMappedRequiredFields,
            })}
            disabled={Boolean(unMappedRequiredFields)}
            onClick={() => {
              incrementStep();
              onStepChange(step + 1);
              onMapCallback(taskMap);
            }}
          >
            {buttonText}
          </button>
          {Boolean(unMappedRequiredFields) && (
            <div className='flex gap-2 justify-center items-center'>
              <Image src='/images/caution-triangle.svg' width={24} height={24} alt='Caution Triangle' />
              <div>
                Please note, column headers that have not been mapped will not be imported.
              </div>
            </div>
          )}
        </div>
      }
    />
  );
}
