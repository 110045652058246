import AccessSettings from './AccessSettings/AccessSettings';
import CollabDiscoveryProvider from './CollabDiscovery.context';
import CollaboratorDiscoverySearchBar from './CollaboratorDiscoverySearchBar';
import CollaboratorsList from './CollaboratorList';

interface CollaboratorDiscoveryModalProps {
  objectId: string;
  closeModal?: () => void;
}

export default function CollaboratorDiscoveryModal ({ objectId, closeModal }: CollaboratorDiscoveryModalProps) {
  return (
    <div
      className='px-6 pt-4 pb-9 w-full bg-white max-w-[750px] shadow-heavy'
      data-testid='collaborator-discovery-modal'
    >
      <div className='flex justify-between items-center pb-1 mb-6 font-medium effra-18'>
        <div>Share</div>
        <i
          onClick={closeModal}
          data-testid='close-collab-modal'
          className='cursor-pointer fa-sharp fa-regular fa-xmark text-[16px] text-gray-60'
        />
      </div>
      <CollabDiscoveryProvider objectId={objectId}>
        <CollaboratorDiscoverySearchBar />
        <CollaboratorsList objectId={objectId} />
        <AccessSettings objectId={objectId} />
      </CollabDiscoveryProvider>
    </div>
  );
}
