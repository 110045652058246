import { Button } from '@/components/Button/Button';
import { Modal } from '@/components/modals/Modal';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectRootState } from '@/state/selectors';
import { closeModal } from '@/state/slices/session.slice';
import cx from 'classnames';
import Image from 'next/legacy/image';

interface ConfirmModalProps {
  isOpen: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
  header: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any;
  cancelText?: string;
  confirmText: string;
  headerClass?: string;
}

export function ConfirmModal (): JSX.Element {
  const dispatch = useAppDispatch();
  const { session: sessionState } = useAppSelector(selectRootState);
  const modalState = sessionState.modal as ConfirmModalProps;

  const _onSubmit = (): void => {
    if (modalState?.onSubmit) {
      modalState?.onSubmit();
    }
    dispatch(closeModal());
  };

  const _onClose = (): void => {
    if (modalState?.onClose) {
      modalState?.onClose();
    }
    dispatch(closeModal());
  };

  return (
    <Modal
      isOpen={modalState?.isOpen}
      width='w-[35rem]'
      onClose={_onClose}
      columns={[
        [
          {
            custom: (
              <div key='confirmation-modal-content'>
                <div
                  className={cx(modalState?.headerClass, 'flex pt-0 pb-6 effra-18 font-black')}
                >
                  {modalState?.header}
                </div>
                <div className='pb-6 whitespace-pre-line'>{modalState?.message}</div>
                <div className='flex flex-row gap-y-4 justify-between'>
                  {modalState?.cancelText && (
                    <Button
                      className='flex justify-center items-center py-6 w-full h-4 text-sm border border-solid cursor-pointer border-primary text-primary'
                      onClick={_onClose}
                    >
                      <span className='inline-block pr-2.5'>
                        <Image src='/images/x.svg' alt='X' height={12} width={16} />
                      </span>
                      {modalState?.cancelText}
                    </Button>
                  )}
                  <Button
                    className='flex justify-center items-center py-6 w-full text-white border border-solid cursor-pointer border-primary bg-primary effra-xs'
                    onClick={_onSubmit}
                  >
                    {modalState?.confirmText}
                  </Button>
                </div>
              </div>
            ),
          },
        ],
      ]}
    />
  );
}
