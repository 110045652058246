import { Listbox } from '@headlessui/react';
import cx from 'classnames';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

interface Option {
  value: string;
  displayText: string;
  icon?: string;
}

interface OptionListProps {
  options: Option[];
  hideSelectionHint?: boolean;
  listOptions?: typeof ListOptions;
}

export function ListOptions ({ value, displayText, icon }: Option): ReturnType<typeof Listbox.Option> {
  return (
    <Listbox.Option
      key={uuidv5(value, uuidv4())}
      value={{ value, displayText, icon }}
      className='flex gap-2 items-center p-2 cursor-pointer h-[32px] effra-12 hover:bg-gray-90'
    >
      <span className='flex justify-center items-center w-[16px] h-[16px]'>
        <i className={icon} />
      </span>
      <span>{displayText}</span>
    </Listbox.Option>
  );
}

export function ListOptionsNoIcon (
  { value, displayText }: Pick<Option, 'value' | 'displayText'>,
): ReturnType<typeof Listbox.Option> {
  return (
    <Listbox.Option
      key={uuidv5(value, uuidv4())}
      value={{ value, displayText }}
      className='flex gap-2 items-center p-2 cursor-pointer h-[32px] effra-12 hover:bg-gray-90'
    >
      <span>{displayText}</span>
    </Listbox.Option>
  );
}

export default function OptionList (
  { options, hideSelectionHint, listOptions = ListOptions }: OptionListProps,
): ReturnType<typeof Listbox.Options> {
  return (
    <Listbox.Options className='w-full max-h-60 bg-white border border-gray-90'>
      <div
        className={cx('p-2 text-gray-40 effra-12', {
          'hidden': hideSelectionHint,
        })}
      >
        Select an option
      </div>
      {options.map(listOptions)}
    </Listbox.Options>
  );
}
