import { Loader } from '@/components';
import MegaModalHeader from '@/components/modals/MegaModal/MegaModalHeader/MegaModalHeader';
import { MegaModalSearchInputV2 } from '@/components/modals/MegaModal/MegaModalSearchInput/MegaModalSearchInput';
import CustomCheckbox from '@/components/modules/CustomCheckbox';
import type { StepContentProps } from '@/components/Stepper/Stepper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSpreadsheet, faTable } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';
import { createRef, type Ref, useMemo, useState } from 'react';

interface SelectExcelSheetScreenProps extends StepContentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedFile: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSelectedSheet: (sheet: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sheetsList: any[];
  isFetchingData: boolean;
}

export default function SelectExcelSheetScreen (
  { selectedFile, handleSelectedSheet, sheetsList, isFetchingData, incrementStep, decrementStep }:
    SelectExcelSheetScreenProps,
): JSX.Element {
  const inputRef: Ref<HTMLInputElement> = createRef();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSheetIdx, setSelectedSheetIdx] = useState<number | null>(null);

  const filteredSheetsList = useMemo(() => {
    if (!searchTerm) return sheetsList || [];
    return sheetsList?.filter((sheet) => sheet.name.toLowerCase().includes(searchTerm.toLowerCase())) || [];
  }, [sheetsList, searchTerm]);

  return (
    <>
      <MegaModalHeader
        title='What sheet do you want to reference?'
        subtext={(filteredSheetsList.length > 1 ? 'This Excel file has multiple sheets. ' : '') +
          'Select the sheet containing the data you\'d like to link.'}
        subTextBlack
      />
      <div className='flex flex-row gap-4 pt-5 effra-14'>
        <FontAwesomeIcon icon={faFileSpreadsheet} className='self-center text-[14px]' />
        {selectedFile.name}
      </div>
      <div className='flex flex-col pt-7 mb-5 min-h-0 gap-[24px] grow'>
        <MegaModalSearchInputV2
          ref={inputRef}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
      </div>
      <div className='flex flex-col gap-5 h-80'>
        <div className='flex flex-row justify-start font-bold effra-12'>
          <span>Name</span>
        </div>
        <div
          className={cx('flex align-middle justify-center transform-gpu transition-all h-80', {
            'hidden': !isFetchingData,
          })}
        >
          <Loader className='w-10 h-10' />
        </div>
        <div
          className={cx('overflow-y-auto h-80 transition-all transform-gpu', {
            'hidden': isFetchingData,
          })}
        >
          {!isFetchingData && filteredSheetsList.map((sheet, idx) => (
            <div
              className='flex flex-row justify-between align-middle hover:cursor-pointer'
              key={`${sheet.name}-${idx}`}
              onClick={() => {
                setSelectedSheetIdx(selectedSheetIdx === idx ? null : idx);
              }}
            >
              <span className='flex flex-row gap-4 items-center effra-14'>
                <CustomCheckbox
                  className='opacity-100 h-[14px] w-[14px]'
                  selected={selectedSheetIdx === idx}
                  onCheck={() => {
                    setSelectedSheetIdx(selectedSheetIdx === idx ? null : idx);
                  }}
                  borderClassName='border rounded bg-white border-gray-70'
                />
                <FontAwesomeIcon icon={faTable} className='self-center text-[14px]' />
                {sheet.name}
              </span>
            </div>
          ))}
          {!isFetchingData && filteredSheetsList.length === 0 && !searchTerm && (
            <div className='flex flex-col gap-2 justify-center items-center align-middle effra-12'>
              <FontAwesomeIcon icon={faTable} className='self-center text-[14px]' />
              <div className='text-center max-w-80'>
                No excel sheets from the selected excel file can be found. Try reconfiguring your integration or contact
                {' '}
                <a href='mailto:support@integrate.co'>support@integrate.co</a> for help.
              </div>
            </div>
          )}
          {!isFetchingData && filteredSheetsList.length === 0 && searchTerm && (
            <div className='flex flex-col gap-2 justify-center items-center align-middle effra-12'>
              <FontAwesomeIcon icon={faTable} className='self-center text-[14px]' />
              <div className='text-center max-w-80'>
                No sheets matching your search term can be found in the selected excel file.
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='flex gap-8 pt-8'>
        <button className='w-full btn-ghost h-[36px]' onClick={decrementStep}>
          back
        </button>
        <button
          disabled={selectedSheetIdx === null}
          onClick={() => {
            handleSelectedSheet(filteredSheetsList[selectedSheetIdx]);
            incrementStep();
          }}
          className='w-full btn-primary h-[36px]'
        >
          continue
        </button>
      </div>
    </>
  );
}
