import { GetTopLevelModularObjectsDocument } from '@/components/Schedule/queries/getTopLevelModularObjects.generated';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useCallback } from 'react';
import { useDeleteCollaboratorMutation } from '../../CollaboratorDiscovery/mutations/deleteShares.generated';
import { GetModularObjectByIdDocument } from '../../ObjectSelectionModal/getModularObjectById.generated';

export function transformCollaboratorInput (selectedCollaborators) {
  return selectedCollaborators.map(({ user, role, externalId, externalType, id }) => ({
    id,
    userId: user?.id,
    user: {
      id: user?.id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      organizationId: user?.organizationId,
      profileImage: user?.profileImage,
    },
    role,
    externalId,
    externalType,
  }));
}

export default function useRemoveCollaborators (selected) {
  const loggedInUser = useLoggedInUser();
  const [removeCollaborators] = useDeleteCollaboratorMutation();

  const isCurrentUserBeingRemoved = selected.find(({ user }) => user.id === loggedInUser.id);

  const handleRemoveCollaborators = useCallback(async () => {
    await removeCollaborators({
      variables: {
        input: transformCollaboratorInput(selected),
      },
      onQueryUpdated: (observableQuery) => {
        void observableQuery.refetch();
        // if (isCurrentUserBeingRemoved) dispatch(closeModularObjectModal());
      },
      refetchQueries: isCurrentUserBeingRemoved
        ? [GetTopLevelModularObjectsDocument, GetModularObjectByIdDocument]
        : [],
    });
  }, [removeCollaborators, selected, isCurrentUserBeingRemoved]);

  return { handleRemoveCollaborators };
}
