import { Loader } from '@/components';
import MegaModalHeader from '@/components/modals/MegaModal/MegaModalHeader/MegaModalHeader';
import type { StepContentProps } from '@/components/Stepper/Stepper';
import { useModal } from '@/hooks/useModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsRotate,
  faFileSpreadsheet,
  faInputNumeric,
  faRectangleWide,
  faTable,
} from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { setTimeout } from 'worker-timers';

interface ShowCurrentMappingScreenProps extends StepContentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedFile: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedSheet: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapping: any;
  moduleId: string;
  isFetchingData: boolean;
  deleteMapping: () => void;
  refreshSubscription: () => void;
}

export default function ShowCurrentMappingScreen (
  {
    selectedFile,
    selectedSheet,
    mapping,
    moduleId,
    isFetchingData,
    deleteMapping,
    refreshSubscription,
    decrementStep,
  }: ShowCurrentMappingScreenProps,
): JSX.Element {
  const { closeModal } = useModal();
  const [refreshCopy, setRefreshCopy] = useState('Refresh');
  const columnNumberToColumnName = useCallback((columnNumber: number) => {
    let dividend = columnNumber + 1;
    let columnName = '';
    let modulo: number;
    while (dividend > 0) {
      modulo = (dividend - 1) % 26;
      columnName = String.fromCharCode(65 + modulo) + columnName;
      dividend = parseInt(((dividend - modulo) / 26).toString(), 10);
    }
    return columnName;
  }, []);

  const lastFetched = useMemo(() => {
    return dayjs(mapping?.modifiedAt);
  }, [mapping?.modifiedAt]);

  return (
    <>
      <MegaModalHeader title='Data Linking from Excel' />
      <div className='flex flex-row gap-4 pt-5 effra-14'>
        <FontAwesomeIcon icon={faFileSpreadsheet} className='self-center text-[14px]' />
        {isFetchingData && !selectedFile?.name
          ? <Loader className='w-5 h-5' />
          : selectedFile?.name}
      </div>
      <div className='flex flex-row gap-4 pt-2 effra-14'>
        <FontAwesomeIcon icon={faTable} className='self-center text-[14px]' />
        {isFetchingData && !selectedSheet?.name
          ? <Loader className='w-5 h-5' />
          : selectedSheet?.name}
      </div>
      <div className='flex flex-row gap-4 pt-2 effra-14'>
        <FontAwesomeIcon icon={faRectangleWide} className='self-center text-[14px]' />
        {columnNumberToColumnName(+mapping?.cellMapping[moduleId]?.column)}
        {+mapping?.cellMapping[moduleId]?.row + 1}
      </div>
      <div className='flex flex-row gap-4 pt-2 effra-14'>
        <FontAwesomeIcon icon={faInputNumeric} className='self-center text-[14px]' />
        {isFetchingData && !selectedSheet?.name
          ? <Loader className='w-5 h-5' />
          : selectedSheet?.data?.[mapping?.cellMapping[moduleId]?.row]?.[mapping?.cellMapping[moduleId]?.column]}
      </div>
      <div className='flex flex-row gap-2 pt-8'>
        Data last fetched {lastFetched.format('MMM D, YYYY')}
        &nbsp; at &nbsp;
        {lastFetched.format('h:mm A')}
        <span
          className={cx('group text-primary flex gap-2', {
            'hover:cursor-pointer': refreshCopy === 'Refresh',
          })}
          onClick={() => {
            setRefreshCopy('Refreshing...');
            refreshSubscription();
            setTimeout(() => {
              setRefreshCopy('Refresh');
            }, 10_000);
          }}
        >
          <FontAwesomeIcon
            icon={faArrowsRotate}
            className={cx('self-center group-hover:animate-spin', {
              'animate-spin': refreshCopy === 'Refreshing...',
            })}
          />
          {refreshCopy}
        </span>
      </div>
      <div className='flex gap-8 pt-8'>
        <button className='w-full btn-ghost h-[36px]' onClick={decrementStep}>
          Update Source
        </button>
        <button className='w-full btn-ghost h-[36px]' onClick={deleteMapping}>
          Delete Cell Link
        </button>
        <button
          onClick={closeModal}
          className='w-full btn-primary h-[36px]'
        >
          Close
        </button>
      </div>
    </>
  );
}
