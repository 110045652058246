import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

function getMappedIcons (options): Map<string, JSX.Element> {
  const map = options.map(({ value, icon }) => [value, <i key={icon} className={icon} />]);
  return new Map(map);
}

export default function useDropDown (onChange, options, initialValue) {
  const placeholder = 'Select an option';

  const listOptionContainerRef = useRef(null);
  const listOptionsRef = useRef(null);

  const [shouldDisplayAbove, setShouldDisplayAbove] = useState(false);

  const [selected, setSelected] = useState({
    displayText: null,
    value: null,
    icon: null,
  });

  const [filteredOptions, setFilteredOptions] = useState(
    options.filter((option) => option.value !== initialValue?.value),
  );

  const handleChange = useCallback((option) => {
    onChange(option?.value);
    setSelected(option);
    setFilteredOptions(options.filter((opt) => opt.value !== option.value));
  }, [onChange, options]);

  useLayoutEffect(() => {
    const containerEl = listOptionContainerRef.current?.offsetParent?.getBoundingClientRect();
    const optionsEl = listOptionsRef.current?.getBoundingClientRect();

    setShouldDisplayAbove((optionsEl?.bottom + filteredOptions.length * 32) > containerEl?.bottom);
  }, [filteredOptions.length, options, listOptionContainerRef, listOptionsRef]);

  const iconMap = getMappedIcons(options);

  return useMemo(() => ({
    listOptionContainerRef,
    listOptionsRef,
    handleChange,
    selected,
    placeholder,
    iconMap,
    shouldDisplayAbove,
    filteredOptions,
  }), [
    listOptionContainerRef,
    listOptionsRef,
    handleChange,
    selected,
    placeholder,
    iconMap,
    shouldDisplayAbove,
    filteredOptions,
  ]);
}
