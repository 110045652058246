import Image from 'next/image';
import BlurredUpdates from 'public/images/blurred_updates.png';
export default function BlockedUpdatesColumn (): JSX.Element {
  return (
    <div className='relative'>
      <Image src={BlurredUpdates} alt='Unavailable Updates Column' className='max-w-[800px]' />
      <div className='flex absolute top-0 justify-center items-center w-full h-full z-2 bg-white/60'>
        <div className='bg-white p-8 shadow-lg md:max-w-[450px] max-w-[90%]'>
          <div className='flex flex-col gap-4'>
            <div className='font-black text-secondary'>Updates log disabled</div>
            <div className='font-effra'>
              Your plan doesn’t include access to the log of updates made to your items over time. Upgrade your plan to
              enable this feature.
            </div>
            <a href='/settings/plan' className='py-6 font-normal text-center uppercase button-primary effra-xs'>
              Upgrade Plan
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
