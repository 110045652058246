import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import getIcon from '@/util/getIcon';
import classNames from 'classnames';
import type { ObjectParentPropsFragment } from './ObjectParentProps.generated';

interface ObjectParentProps {
  object: ObjectParentPropsFragment;
}

export default function ObjectParent ({ object }: ObjectParentProps) {
  const loggedInUser = useLoggedInUser();

  if (!object?.name) return <>—</>;
  const isInternal = object?.owner?.organizationId === loggedInUser?.organizationId ||
    object?.owner?.id === loggedInUser.id;

  return (
    <div className='flex gap-2 items-center truncate'>
      <div title={object?.name}>
        {getIcon(object?.template?.name.toLowerCase(), 'w-[20px] shrink')}
      </div>
      <div
        title={object?.name}
        className={classNames('font-effra font-bold leading-[20px] truncate', {
          'text-tertiary': !isInternal,
          'text-primary': isInternal,
        })}
      >
        {object?.name}
      </div>
    </div>
  );
}
