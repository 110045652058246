import OptionList, { ListOptionsNoIcon } from '@/designSystemComponents/Dropdown/OptionList';

interface PageSelectionOptionListProps {
  hideSelectionHint: boolean;
  options: Array<{ value: string; displayText: string }>;
}

export default function PageSelectionOptionList ({ hideSelectionHint, options }: PageSelectionOptionListProps) {
  return (
    <OptionList
      options={options}
      listOptions={ListOptionsNoIcon}
      hideSelectionHint={hideSelectionHint}
    />
  );
}
