import getIcon from '@/util/getIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRetweet, faSquarePlus, faXmark } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import StatusTooltip, { type ApprovalStatus } from '../tooltip/StatusTooltip';

interface ParentSelectButtonProps {
  readonly iconTemplateName?: string;
  readonly label: string;
  readonly status?: ApprovalStatus;
  readonly onClick: (event) => Promise<void> | void;
  readonly onDeleteClick: (event) => Promise<void> | void;
  readonly onSwapClick: (event) => Promise<void> | void;
  readonly onViewClick: (event) => Promise<void> | void;
  readonly disabled?: boolean;
  readonly hoverOverride?: boolean; // Just for testing purposes in storybook
}

export function ParentSelectButton (
  {
    iconTemplateName,
    label,
    status,
    onClick,
    onDeleteClick,
    onSwapClick,
    onViewClick,
    disabled,
    hoverOverride = false,
  }: ParentSelectButtonProps,
): JSX.Element {
  const [isHovered, setIsHovered] = useState(hoverOverride);

  const _setIsHovered = useCallback((_hovered) => {
    if (!hoverOverride) {
      setIsHovered(_hovered);
    }
  }, [hoverOverride]);

  // This function handles the detection of a user utilizing tab navigation
  // as the design of this button introduces some tough ref passing/focusing
  // that was deemed unnecessarily complex to handle, so we just expose the
  // "hovered" state as soon as we detect tab navigation to make the experience
  // more natural
  const handleKeyUp = useCallback(e => {
    if (e.keyCode === 9) { // Tab key
      if (!isHovered) {
        _setIsHovered(true);
      }
    }
  }, [isHovered, _setIsHovered]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp]);

  useEffect(() => {
    setIsHovered(hoverOverride);
  }, [hoverOverride]);

  function handleBlur (e) {
    _setIsHovered(false);
  }

  function handleMouseOver () {
    _setIsHovered(true);
  }

  function handleMouseOut () {
    _setIsHovered(false);
  }

  function unhandleBlur () {
    // Only here to satisfy SonarLint's rule(sonarlint(typescript:S1082)), onBlur in this scenario is unnecessary as a div by default does not have the ability to focus or blur
    // and the 3 other buttons rendered inside the div handle the focus/blurring on their own
    return null;
  }

  const outerClassNames = 'effra-14 font-medium flex items-center gap-[6px] leading-[20px]';

  const innerClassNames = cx({
    'opacity-40': !disabled && (!iconTemplateName && !isHovered) || status,
  });

  if (!iconTemplateName) {
    return (
      <div className={cx(outerClassNames)} data-testid='empty-button'>
        {status && <StatusTooltip status={status} />}
        <button
          className={cx(outerClassNames, 'overflow-hidden')}
          onClick={onClick}
          onBlur={handleBlur}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          disabled={disabled}
          type='button'
        >
          <FontAwesomeIcon icon={faSquarePlus} className={cx(innerClassNames, 'w-[15px]')} title={label} />
          <span className={cx(innerClassNames, 'truncate')} data-testid='empty-label'>{label}</span>
        </button>
      </div>
    );
  }

  if (!isHovered || status || disabled) {
    return (
      <div className={cx(outerClassNames)} data-testid='parent-button'>
        {status && <StatusTooltip status={status} />}
        <button
          className={cx(innerClassNames, 'gap-[6px]', 'flex', 'overflow-hidden', 'items-center')}
          disabled={disabled}
          type='button'
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <div>
            {getIcon(iconTemplateName, cx(innerClassNames, 'w-[15px]', 'overflow-visible'))}
          </div>
          <span data-testid='parent-label' className='truncate shrink'>{label}</span>
        </button>
      </div>
    );
  }

  return (
    <div
      className={cx(outerClassNames)}
      onMouseLeave={handleBlur}
      onBlur={unhandleBlur}
      data-testid='parent-button-hovered'
    >
      {status && <StatusTooltip status={status} />}
      <button
        onClick={onSwapClick}
        className={cx(innerClassNames, 'overflow-visible')}
        title='Swap object'
        disabled={disabled}
        type='button'
      >
        <FontAwesomeIcon icon={faArrowsRetweet} className='w-[15px]' />
      </button>
      <button
        onClick={onViewClick}
        className={cx(innerClassNames, 'truncate')}
        title='View object'
        disabled={disabled}
        type='button'
      >
        {label}
      </button>
      <button
        onClick={onDeleteClick}
        className={cx(innerClassNames, 'shrink')}
        title='Remove object'
        disabled={disabled}
        type='button'
      >
        <FontAwesomeIcon icon={faXmark} className='opacity-40' />
      </button>
    </div>
  );
}
