import { useAppDispatch } from '@/state/hooks';
import { setModularObjectModal } from '@/state/slices/session.slice';
import cx from 'classnames';

export interface BreadCrumbModel {
  text: string;
  onClick?: () => void;
}

interface BreadcrumbProps {
  className?: string;
  crumbs: BreadCrumbModel[];
  onClick?: () => void;
}

export function Breadcrumb ({ className, crumbs }: BreadcrumbProps): JSX.Element {
  const dispatch = useAppDispatch();
  const handleClick = (crumb): void => {
    if (typeof crumb?.onClick === 'function') {
      crumb.onClick();
    } else {
      dispatch(setModularObjectModal({
        modularObjectId: crumb.id,
        templateId: crumb.templateId,
      }));
    }
  };

  return (
    <div className={className} data-testid='breadcrumb-div'>
      {crumbs.map((crumb, idx) => {
        const crumbText = crumb.text;
        // if parent is not visible to user
        const notVisible = crumbText.includes('Unknown');

        const isLast = idx === crumbs.length - 1;
        const Element = isLast || notVisible ? 'span' : 'button';

        return (
          <Element
            key={`${crumbText}-${idx}`}
            onClick={notVisible ? null : () => {
              handleClick(crumb);
            }}
            className={cx('font-bold', {
              'text-primary mr-1': !isLast && !notVisible,
            })}
          >
            {notVisible ? 'Parent (Not visible to you) > ' : `${crumbText}${!isLast ? ' > ' : ''}`}
          </Element>
        );
      })}
    </div>
  );
}
