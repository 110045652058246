import ListBoxButton, {
  DefaultSlimButton,
  type ListBoxButtonProps,
} from '@/designSystemComponents/Dropdown/ListBoxButton';

type PageSelectionDropdownButtonProps = ListBoxButtonProps<{ icons?: Map<string, JSX.Element> }>;

export default function PageSizeSelectionDropdownButton (
  { selected, placeholder, initialValue }: PageSelectionDropdownButtonProps,
) {
  return (
    <ListBoxButton<PageSelectionDropdownButtonProps>
      selected={selected}
      placeholder={placeholder}
      initialValue={initialValue}
      button={DefaultSlimButton}
    />
  );
}
