import Image from 'next/image';

interface PendingInviteProps {
  isAnonymousUser: boolean;
  email: string;
}

export default function PendingInvite ({ isAnonymousUser, email }: PendingInviteProps): JSX.Element | null {
  if (!isAnonymousUser) return null;

  return (
    <div className='flex items-center'>
      <div className='flex items-center bg-black rounded-full w-[40px] h-[40px] shrink-0'>
        <Image
          src={'/images/default-profile-icon.png'}
          alt='Profile Image'
          height={32}
          width={32}
          className='rounded-full'
          style={{ minWidth: '100%', minHeight: '100%', maxWidth: '100%', maxHeight: '100%' }}
        />
      </div>
      <div className='flex flex-col pl-3'>
        <span className='effra-14'>{email}</span>
        <span className='effra-12 text-[#666]'>Pending Invite</span>
      </div>
    </div>
  );
}
