import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { ScheduleModularObjectFragmentDoc } from '../../../graphql/fragments/modularObject.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetTopLevelModularObjectsQueryVariables = Types.Exact<{
  filters: Types.InputMaybe<Types.Filters>;
}>;


export type GetTopLevelModularObjectsQuery = { __typename?: 'Query', getTopLevelModularObjects: Array<{ __typename?: 'ModularObject', id: string, name: string | null, startDate: any | null, targetDate: any | null, lValue: number | null, assigneeId: string | null, status: Types.TaskStatus | null, statusModuleId: string | null, templateId: string | null, ownerId: string | null, visibility: string | null, deactivated: boolean | null, permission: Types.Permission | null, assignee: { __typename?: 'User', id: string, email: string | null, organizationId: string | null } | null, duration: { __typename?: 'ModularObjectDuration', startDate: any | null, targetDate: any | null } | null, template: { __typename?: 'Template', id: string | null, name: string | null, subType: Types.TemplateSubTypes | null, type: Types.TemplateTypes | null } | null, owner: { __typename?: 'User', id: string, email: string | null, organizationId: string | null } | null, dependencies: Array<{ __typename?: 'Dependency', id: string, modularObjectId: string | null, blockedById: string | null, activeAt: any | null }> | null, children: Array<{ __typename?: 'ModularObject', id: string, name: string | null, permission: Types.Permission | null, startDate: any | null, targetDate: any | null, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null, name: string | null } | null }> | null, parent: { __typename?: 'ModularObject', id: string, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null, name: string | null } | null, duration: { __typename?: 'ModularObjectDuration', startDate: any | null, targetDate: any | null } | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, role: string | null, userId: string | null, user: { __typename?: 'User', id: string, organizationId: string | null } | null }> | null }> | null };


export const GetTopLevelModularObjectsDocument = gql`
    query GetTopLevelModularObjects($filters: Filters) {
  getTopLevelModularObjects(filters: $filters) {
    ...ScheduleModularObject
  }
}
    ${ScheduleModularObjectFragmentDoc}`;

/**
 * __useGetTopLevelModularObjectsQuery__
 *
 * To run a query within a React component, call `useGetTopLevelModularObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopLevelModularObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopLevelModularObjectsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetTopLevelModularObjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetTopLevelModularObjectsQuery, GetTopLevelModularObjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopLevelModularObjectsQuery, GetTopLevelModularObjectsQueryVariables>(GetTopLevelModularObjectsDocument, options);
      }
export function useGetTopLevelModularObjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopLevelModularObjectsQuery, GetTopLevelModularObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopLevelModularObjectsQuery, GetTopLevelModularObjectsQueryVariables>(GetTopLevelModularObjectsDocument, options);
        }
export function useGetTopLevelModularObjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTopLevelModularObjectsQuery, GetTopLevelModularObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTopLevelModularObjectsQuery, GetTopLevelModularObjectsQueryVariables>(GetTopLevelModularObjectsDocument, options);
        }
export type GetTopLevelModularObjectsQueryHookResult = ReturnType<typeof useGetTopLevelModularObjectsQuery>;
export type GetTopLevelModularObjectsLazyQueryHookResult = ReturnType<typeof useGetTopLevelModularObjectsLazyQuery>;
export type GetTopLevelModularObjectsSuspenseQueryHookResult = ReturnType<typeof useGetTopLevelModularObjectsSuspenseQuery>;
export type GetTopLevelModularObjectsQueryResult = Apollo.QueryResult<GetTopLevelModularObjectsQuery, GetTopLevelModularObjectsQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetTopLevelModularObjectsQuery((req, res, ctx) => {
 *   const { filters } = req.variables;
 *   return res(
 *     ctx.data({ getTopLevelModularObjects })
 *   )
 * })
 */
export const mockGetTopLevelModularObjectsQuery = (resolver: Parameters<typeof graphql.query<GetTopLevelModularObjectsQuery, GetTopLevelModularObjectsQueryVariables>>[1]) =>
  graphql.query<GetTopLevelModularObjectsQuery, GetTopLevelModularObjectsQueryVariables>(
    'GetTopLevelModularObjects',
    resolver
  )
