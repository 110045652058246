import cx from 'classnames';

interface CustomCheckBoxIconProps {
  selected: boolean;
  onCheck?: (selected: boolean, e: React.MouseEvent) => void;
  onClick?: (e: React.MouseEvent, selected: boolean) => void;
  disabled?: boolean;
  className?: string;
  borderClassName?: string;
  checkmarkClassName?: string;
  showOnHoverOnly?: boolean;
}

const ALIGNMENT_CLASSES = 'flex items-center justify-center transition-all';
const BORDER_CLASSES = 'border rounded border-gray-70 bg-white';
const CHECKMARK_CLASSES = 'fa-sharp fa-solid fa-check text-black flex';

export default function CustomCheckbox (
  { selected, onCheck, onClick, disabled, className, borderClassName, checkmarkClassName, showOnHoverOnly }:
    CustomCheckBoxIconProps,
): JSX.Element {
  const defaultClasses = showOnHoverOnly ?
    cx(
      'h-0 w-0 group-hover:opacity-100 group-hover:w-[14px] group-hover:h-[14px]',
      {
        'w-0 h-0 opacity-0': !selected,
        'w-[14px] h-[14px] opacity-100': selected,
      },
    ) :
    'h-[14px] w-[14px]';
  const handleClick = (e) => {
    if (!disabled && onCheck && typeof onCheck === 'function') {
      e.stopPropagation();
      e.preventDefault();
      onCheck(!selected, e);
    }

    if (!disabled && onClick && typeof onClick === 'function') {
      e.stopPropagation();
      e.preventDefault();
      onClick(e, !selected);
    }
  };

  return (
    <span
      data-testid='custom-checkbox'
      onClick={handleClick}
      className={cx(
        ALIGNMENT_CLASSES,
        borderClassName ?? BORDER_CLASSES,
        className ?? defaultClasses,
        {
          '!opacity-30': disabled,
        },
      )}
    >
      {selected && (
        <i
          className={cx(checkmarkClassName ?? 'text-[10px]', CHECKMARK_CLASSES)}
        />
      )}
    </span>
  );
}
