import { API_URL } from '@/util/constants';
import { env } from '@/util/env.utils';
import { ApolloLink, HttpLink, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { getCookie } from 'cookies-next';
import { createClient, type WebSocket } from 'graphql-ws';

export const apolloLink = new HttpLink({
  uri: `/graphql`,
  credentials: 'include',
  fetch: (uri, options) => {
    if (!options.headers) {
      options.headers = {};
    }
    generateHeaders().forEach((value, key) => {
      options.headers[key] = value;
    });

    return fetch(`${API_URL()}${uri}`, options);
  },
});

export const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    if (!headers) {
      headers = {};
    }
    generateHeaders().forEach((value, key) => {
      headers[key] = value;
    });
    return {
      headers,
    };
  });

  return forward(operation);
});

export const subscriptionLink = new GraphQLWsLink(createClient({
  url: `${env('API_WS_PROTOCOL')}://${env('NEXT_PUBLIC_API_DOMAIN')}/graphql`,
  shouldRetry: () => true,
  retryAttempts: Infinity,
  connectionParams: {
    headers: generateHeaders(),
  },
  on: {
    closed: () => {
      if (typeof window === 'undefined') {
        return;
      }

      const token = getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'));
      if (!token) {
        // The user is not authenticated, so we closed the connection prematurely; No need to log
        return;
      }

      const now = new Date();
      console.info('[websocket] connection closed', now.toISOString());
    },
    opened: () => {
      if (typeof window === 'undefined') {
        return;
      }

      const token = getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'));
      if (!token) {
        // Silently return when no cookie as I don't want to log when it will fail
        return;
      }

      const now = new Date();
      console.info('[websocket] connection opened', now.toISOString());
    },
    connected: async (socket: WebSocket) => {
      if (typeof window === 'undefined') {
        return;
      }

      const token = getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'));
      if (!token) {
        // The user is not authenticated, so we close the connection prematurely
        await socket.close(4205, 'Client Restart');
        return;
      }

      const now = new Date();
      console.info('[websocket] connection established', now.toISOString());
    },
    connecting: () => {
      if (typeof window === 'undefined') {
        return;
      }

      const token = getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'));
      if (!token) {
        // Silently return when no cookie as I don't want to log when it will fail
        return;
      }

      const now = new Date();
      console.info('[websocket] connecting', now.toISOString());
    },
    error: (error) => {
      if (typeof window === 'undefined') {
        return;
      }
      const now = new Date();
      console.info('[websocket] error', error, now.toISOString());
    },
    message: (message) => {
      if (message.type !== 'next' || typeof window === 'undefined') {
        return;
      }
      const now = new Date();
      console.info('[websocket] message', message, now.toISOString());
    },
  },
}));

export const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    );
  },
  subscriptionLink,
  apolloLink,
);

function generateHeaders (headers: Headers = new Headers()) {
  const token = getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'));

  if (typeof window !== 'undefined') {
    const impersonationEmail = localStorage.getItem('impersonation');

    if (impersonationEmail) {
      headers.set('x-impersonate', impersonationEmail);
    }
  }

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  return headers;
}
