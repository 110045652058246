import { useGetCollaboratorListQuery } from '@/components/modals/CollaboratorDiscovery/queries/getCollaboratorList.generated';
import { not, pipe } from 'ramda';
import { useCallback, useState } from 'react';

function isCurrentUserSelected (user) {
  return ({ user: selectedUser }) => selectedUser?.id === user.id;
}

export function useConfigureCollaboratorQueue (collaboratorData) {
  const [selected, setSelected] = useState([]);

  const objectsWithShares = collaboratorData?.getModularObjectByIDs ?? [];
  const shares = objectsWithShares.flatMap(({ id, shares }) =>
    shares.map(share => ({ ...share, externalId: id, externalType: 'modular_object' }))
  );

  const collaborators = shares
    .map(({ user }) => user)
    .filter((user, index, self) => self.findIndex(u => JSON.stringify(u) === JSON.stringify(user)) === index);

  const addCollaborator = useCallback((user) => {
    const isUserSelected = isCurrentUserSelected(user);
    setSelected((prev) => {
      const newSelectedCollabs = prev.find(isUserSelected)
        ? prev.filter(pipe(isUserSelected, not))
        : [...prev, ...shares.filter(isUserSelected)];

      return newSelectedCollabs;
    });
  }, [shares]);

  return { selected, collaborators, addCollaborator };
}
export default function useCollaboratorRemovalQueue (selectedObjectIds) {
  const { data: collaboratorData, loading: isLoading } = useGetCollaboratorListQuery({
    fetchPolicy: 'network-only',
    variables: {
      ids: selectedObjectIds,
    },
  });

  const config = useConfigureCollaboratorQueue(collaboratorData);

  return { ...config, isLoading };
}
