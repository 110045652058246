import { useFlags } from 'launchdarkly-react-client-sdk';
import Script from 'next/script';

export default function ZendeskChatWidget (): JSX.Element {
  const { zendeskChat } = useFlags();

  if (!zendeskChat) return null;

  return (
    <Script
      id='ze-snippet'
      src='https://static.zdassets.com/ekr/snippet.js?key=1390e21d-5361-49db-ac5c-5c8cacfd71d0'
    />
  );
}
