import getIcon from '@/util/getIcon';

interface ObjectNameCellProps {
  name: string;
  objectType: string;
}

export function ObjectNameCell ({ name, objectType }: Readonly<ObjectNameCellProps>): JSX.Element {
  return (
    <div data-testid='object-name' className='flex items-center flex-[2]'>
      <span className='flex items-center shrink-0'>
        {getIcon(objectType)}
      </span>
      <span className='block ml-4 font-medium text-primary effra-14 shrink truncate sm:w-[3ch] md:w-[5ch] lg:w-[10ch] xl:w-[12ch] 2xl:w-[20ch]'>
        {name}
      </span>
    </div>
  );
}
