import type { Share } from '@/__generated__/types';
import { Button } from '@/designSystemComponents/Button';
import { useCallback } from 'react';
import useRemoveCollaborators from './hooks/useRemoveCollaborators';

interface RemoveButtonProps {
  selected: Share[];
  closeModal: () => void;
  disabled: boolean;
}

export default function RemoveButton ({ selected, closeModal, disabled }: RemoveButtonProps) {
  const { handleRemoveCollaborators } = useRemoveCollaborators(selected);

  const clickHandler = useCallback(async () => {
    await handleRemoveCollaborators();
    closeModal();
  }, [handleRemoveCollaborators, closeModal]);

  return (
    <Button
      figmaProps={{ style: 'fill', size: 'small' }}
      onClick={clickHandler}
      disabled={disabled}
    >
      remove
    </Button>
  );
}
