import { useCallback } from 'react';
import fileSelectionHandler from './lib/fileSelectionHandler';
import getFileFromUserInput from './lib/getFileFromUserInput';
import preventDefaults from './lib/preventDefaults';
import toggleDragClasses from './lib/toggleDragClasses';

import type { DragEvent, DropCallback } from './types';

interface DragAreaProps {
  onDropCallback: DropCallback;
  setError: (error: string) => void;
}

function handleDragOver (e: DragEvent): void {
  preventDefaults(e);
  toggleDragClasses('add');
  e.dataTransfer.dropEffect = 'copy';
}

function handleDragLeaveOrDrop (e: DragEvent): void {
  preventDefaults(e);
  toggleDragClasses('remove');
}

export default function DragArea ({ onDropCallback, setError }: DragAreaProps): JSX.Element {
  const dropHandler = useCallback((e) => {
    handleDragLeaveOrDrop(e);
    fileSelectionHandler(onDropCallback, setError, getFileFromUserInput(e));
  }, []);

  return (
    <div
      id='draggable-file-area'
      onDrop={dropHandler}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeaveOrDrop}
      className='flex flex-col justify-center items-center mt-3 w-full text-center border border-dashed border-gray-dark h-[256px]'
    >
      <p className='font-black font-effra text-[16px]'>Drag Template Here</p>
      <label className='block relative w-32 h-10'>
        <input type='file' onChange={dropHandler} title='click to browse' className='absolute left-0 opacity-0' />
        <span className='absolute left-0 underline whitespace-nowrap cursor-pointer text-primary'>
          or click to browse
        </span>
      </label>
    </div>
  );
}
